<template>
  <div class="add-template__content">
    <div class="add-template__temps scroll">
      <ul class="add-template__list" v-if="templates.length">
        <temp-card
            v-for="template in templates"
            :key="template.id"
            :template="template"
            :is-selected="checkSelection(template.id)"
            :is-favourite="isFavourite(template.id)"
            @click.native="$emit('selected', template)"
        />
      </ul>
      <s-empty v-else class="add-template__temps-empty"/>
    </div>
    <div class="add-template__preview scroll">
      <p class="add-template__preview-empty" v-if="!lastSelectedTemplate">Select a template to preview</p>
      <template v-else>
        <div class="add-template__preview-img" v-for="preview in lastSelectedTemplate.previews" :key="preview" >
          <img :src="resolveTemplateGraphicSrc(lastSelectedTemplate.id, preview)" alt="">
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { resolveTemplateGraphicUrl, resolveTemplateGraphicSrc } from '@/view-helpers/template-preview-resolver'
import TempCard from '@/components/links/components/TempCard.vue'
import Empty from '@/components/layout/Empty.vue'

export default {
  name: 'TemplatePreview',
  components: {
    TempCard,
    SEmpty: Empty
  },
  props: {
    lastSelectedTemplate: Object,
    showPreview: Boolean,
    selectedTemplates: {
      type: Array,
      default: () => []
    },
    templates: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    ...mapGetters('templates', ['isFavourite'])
  },
  methods: {
    resolveTemplateGraphicSrc,
    checkSelection(id) {
      return this.selectedTemplates.includes(id);
    },
    makeImgStyle(fileName) {
      return { backgroundImage: resolveTemplateGraphicUrl(this.lastSelectedTemplate.id, fileName) };
    }
  }
}
</script>
<style lang="less">
</style>
