<template>
    <div class="scroll" style="height: calc(100vh - (128px + 16px));">
      <gallery-items-table
          :is-loading="isLoading"
          :page-size="pageSize"
          :current-page="currentPage"
          :total-items="totalItems"
          :gallery-items="galleryCollection"
          :query-text="searchText"
          @pageChanged="handlePaginationChange($event)"
          @itemArchive="handleItemArchive($event)"
          @itemEdit="handleItemEdit($event)"
          @itemRemove="handleItemRemove($event)"
      />
    </div>
</template>

<script>
import {galleryService} from "@/services/gallery.service";
import GalleryItemsTable from "@/components/gallery/components/GalleryItemsTable.vue";
import {galleryItemMapper} from "@/components/gallery/model/gallery-item";
import {mapActions} from "vuex";

export default {
  name: 'GeneralUseGalleryPage',
  components: { GalleryItemsTable },
  props: {
    newUploadedFile: {
      type: [Object, Array],
      default: () => []
    },
    searchText: {
      type: String,
      default: ''
    },
    sortBy: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      galleryCollection: [],
      isLoading: true,
      currentPage: 0,
      totalItems: 0,
      pageSize: 20,
      req: {
        getAllGeneralUse: galleryService.getAll(),
      }
    }
  },
  async mounted() {
    await this.loadGeneralUse();
  },
  methods: {
    ...mapActions(['showAlertSuccess', 'showAlertError']),
    async handleItemArchive(galleryItem) {
      try {
        await galleryService.archiveGalleryCampaignItem(galleryItem.id)
        await this.loadGeneralUse();
        await this.showAlertSuccess(`Item ${galleryItem.name} archived.`)
      } catch (e) {
        console.error(e);
      }
    },
    handleItemEdit(galleryItem) {
      this.$emit('itemEdit', galleryItem)
    },
    async handleItemRemove(galleryItem) {
      try {
        await galleryService.removeItem(galleryItem.id)
        this.showAlertSuccess(galleryItem.name + ' removed.')
        await this.loadGeneralUse();
      } catch (e) {
        this.showAlertError(galleryItem.name + ' cannot be removed.')
      }
    },
    handlePaginationChange(current) {
      this.currentPage = current;
      this.loadGeneralUse()
    },
    async loadGeneralUse() {
      this.isLoading = true;
      this.req.getAllGeneralUse = galleryService.getAll(
          this.searchText,
          {page: this.currentPage, limit: this.pageSize},
          {orderBy: this.sortBy.current, orderDir: this.sortBy.direction }
      );
      try {
        const { results, total } = await this.req.getAllGeneralUse.call();
        this.galleryCollection = results.map(galleryItemMapper)
        this.totalItems = total;
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false;
      }
    }
  },
  watch: {
    newUploadedFile() {
      // On purpose - when new file loaded - RELOAD list.
      this.loadGeneralUse()
    },
    searchText() {
      this.currentPage = 0;
      this.loadGeneralUse()
    },
    sortBy() {
      console.log('SORT BY!')
      this.loadGeneralUse()
    }
  },
  beforeDestroy() {
    this.req.getAllGeneralUse.cancel();
  }
}
</script>

<style scoped lang="less">

</style>
