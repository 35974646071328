var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edition"},[_c('h4',{staticClass:"edition__title box__title",on:{"click":function($event){return _vm.expandAll()}}},[_vm._v(" Edition "),_c('a-icon',{style:({
                transform: _vm.allExpanded ? 'rotate(90deg)' : '',
                transition: 'transform 0.3s',
                cursor: 'pointer',
            }),attrs:{"type":"right-square","title":_vm.expandAllTitle}})],1),_c('div',{staticClass:"scroll",staticStyle:{"height":"calc(100vh - 128px - 36px - 36px)","overflow-x":"hidden"}},[(_vm.loading)?_c('div',{staticClass:"box__empty"},[_c('s-preloader',{attrs:{"text":"Forms initialization…"}})],1):(!_vm.hasAnyModels && !_vm.loading)?_c('div',{staticClass:"box__empty"},[_c('p',[_vm._v("Select a connection to start")])]):[(_vm.hasAnyModels)?[_c('a-collapse',{attrs:{"expand-icon-position":"right"},model:{value:(_vm.expanded),callback:function ($$v) {_vm.expanded=$$v},expression:"expanded"}},[(_vm.hasMirrorFields)?_c('a-collapse-panel',{key:"MirrorFields",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"edition__collapse_header for--mirror"},[_vm._v(" Mirror fields "),_c('a-icon',{attrs:{"type":"branches"}})],1)]},proxy:true}],null,false,1682954016)},[_c('div',{staticClass:"scroll",staticStyle:{"overflow-x":"hidden"}},[_c('mirror-template-fields-update-form',{attrs:{"mirror-fields":_vm.mirrorFields},on:{"mirrorUpdated":function($event){return _vm.handleControlUpdate(
                                        $event.action,
                                        $event.templateModel,
                                        { templateId: $event.templateId }
                                    )}}})],1)]):_vm._e(),_vm._l((_vm.templateModels),function(tempModel){return _c('a-collapse-panel',{key:("T" + (tempModel.id)),scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"edition__collapse_header"},[_vm._v(" "+_vm._s(tempModel.name)+" "),_c('a-icon',{attrs:{"type":"layout"}})],1)]},proxy:true}],null,true)},[_c('div',{staticClass:"scroll",staticStyle:{"overflow-x":"hidden","min-height":"200px"}},[_c('template-form',{staticClass:"edition__box",attrs:{"template":tempModel},on:{"controlUpdated":function($event){return _vm.handleControlUpdate($event, tempModel, {
                                        templateId: tempModel.id,
                                    })}}})],1)])}),_vm._l((_vm.templateModelsForProducts),function(tempModel){return _c('a-collapse-panel',{key:("P" + (tempModel.productId)),scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"edition__collapse_header"},[_vm._v(" "+_vm._s(_vm.extractProductData(tempModel.settings) .name)+" "),_c('a-icon',{attrs:{"type":"shopping-cart"}})],1)]},proxy:true}],null,true)},[_c('product-form',{staticClass:"edition__box",attrs:{"productId":tempModel.productId,"template":tempModel,"controlsSettings":tempModel.settings,"product-data":_vm.extractProductData(tempModel.settings),"has-more-than-one-product-templates":_vm.hasMoreThanOneProductTemplates},on:{"controlUpdated":function($event){return _vm.handleControlUpdate($event, tempModel, {
                                    productId: tempModel.productId,
                                })}}})],1)})],2)]:_vm._e()]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }