<template>
  <div v-if="notAllFrozenAndWithoutPossibleValues">
    <p class="form__label">Text - {{ identifier }}</p>
    <div style="padding-bottom: .75em"  v-if="!freezeText  || possibleTextValues.length > 0" >
      <component
          :is="possibleTextValues.length === 0 ? 'renewable-input' : 'renewable-select'"
          v-model="text.value"
          :can-edit="!freezeText"
          :options="possibleTextValues"
          @input="handleInputChange('value', $event)"
          @reset="handleInputReset('value')"
          @select="handleOptionSelectedFromDropdown($event)"
      />
    </div>
    <div class="edition__font">
      <div v-if="!freezeFont  || possibleFontValues.length > 0" class="edition__font-size">
        <p class="form__label">Font</p>
        <renewable-select
            v-model="text.font"
            @change="handleInputChange('font', $event)"
            @reset="handleInputReset('font')"
        >
          <a-select-option
              v-for="fontName in templateFontNames"
              :key="fontName"
          >
            {{ fontName }}
          </a-select-option>
        </renewable-select>
      </div>
      <div v-if="!freezeWeight" class="edition__font-weight">
        <p class="form__label">Weight</p>
        <renewable-select
            v-model="text.weight"
            @change="handleInputChange('weight', $event)"
            @reset="handleInputReset('weight')"
        >
          <a-select-option
              v-for="fontWeight in chosenFontWeights"
              :key="fontWeight"
          >
            {{ fontWeight }}
          </a-select-option>
        </renewable-select>
      </div>
      <text-style-changer
          :weight="text.weight"
          :font-style="text.fontStyle"
          :text-decoration-line="text.textDecorationLine"
          :enabled-options="enabledStyles"
          @change="handleInputChange($event.type, $event.value)"
      />
      <div v-if="!freezeColor || hasPossibleColorValues" class="edition__font-color" style="margin-left: 10px">
        <p class="form__label">Color</p>
        <color-picker
            v-model="text.color"
            :possibleValues="possibleColorValues"
            :allow-to-pick-any-color="!freezeColor"
            @change="handleInputChange('color', $event)"
            @reset="handleInputReset('color')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { DataHideHtmlTextWrapper } from '@/components/project/components/form/DataHideHtmlTextWrapper';
import { possibleValuesMixin } from '@/components/project/components/form/possible-values.mixin';
import { filterFonts } from '@/components/shared/form/form-fonts';
import RenewableInput from '@/components/shared/form/RenewableInput';
import RenewableSelect from '@/components/shared/form/RenewableSelect';
import TextStyleChanger from '@/components/shared/form/TextStyleChanger';
import ColorPicker from '@/components/shared/form/ColorPicker';
import { TemplateText } from '@/templating/TemplateText';

// Make Freezable property enum:
const FREEZABLE = TemplateText.freezable.reduce((enumObj, keyAndValue) => {
  enumObj[keyAndValue] = keyAndValue;
  return enumObj;
}, {});

const KEY_VALUE = 'value';
const KEY_COLOR = 'color';
const KEY_FONT = 'font';
const KEY_TEXT = 'text'; // This is @Legacy mismatch (between TControl and default values in template)

export default {
  name: 'FormText',
  components: {
    RenewableInput,
    RenewableSelect,
    TextStyleChanger,
    ColorPicker
  },
  props: {
    identifier: {
      type: String,
      required: true
    },
    templateControl: {
      type: TemplateText,
      required: true
    },
    templateUsedFonts: {
      default: () => ({})
    },
    settings: {
      default: () => ({})
    },
    serverDefinedFrozenFields: {
      default: () => ({})
    }
    // serverDefinedPossibleValues from Mixin !
  },
  mixins: [possibleValuesMixin],
  data() {
    return {
      text: {
        value: this.settings?.value,
        font: this.settings?.font,
        weight: this.settings?.weight,
        fontStyle: this.settings?.fontStyle,
        textDecorationLine: this.settings?.textDecorationLine,
        color: this.settings?.color,
      }
    };
  },
  computed: {
    templateFontNames() {
      let fontNamesToChooseFrom = this.templateUsedFonts?.names || [];
      if( this.possibleFontValues.length > 0 ) {
        fontNamesToChooseFrom = filterFonts(fontNamesToChooseFrom, this.possibleFontValues);
      }
      return fontNamesToChooseFrom;
    },
    templateFonts() {
      return this.templateUsedFonts?.list || {};
    },
    chosenFontWeights() {
      const { font } = this.text;
      return this.templateFonts?.[font]?.fontWeight || [];
    },
    enabledStyles() {
      const { font } = this.text;
      const { fontStyle = [], fontWeight = [] } = this.templateFonts[font] || {};
      let enabledOptions = [FREEZABLE.underline, ...fontStyle];
      if( fontWeight.length > 0 ) {
        enabledOptions.push(FREEZABLE.weight);
      }
      // Get only not frozen options:
      enabledOptions = enabledOptions.filter(value => !(
          this.serverDefinedFrozenFields?.[value] ||
          this.templateControl?.freeze?.[value]
      ));
      return enabledOptions;
    },
    // @Business: Control should NOT be displayed when it is FROZEN nad without possibleValues
    notAllFrozenAndWithoutPossibleValues() {
      const allFieldsFrozen = (
          this.freezeColor &&
          this.freezeFont &&
          this.freezeText &&
          this.freezeWeight &&
          this.serverDefinedFrozenFields?.[FREEZABLE.underline] &&
          this.serverDefinedFrozenFields?.[FREEZABLE.italic]
      );
      const allFieldsWithoutPossibleValues = !(
          this.possibleTextValues.length ||
          this.possibleFontValues.length ||
          this.possibleColorValues.length
      )
      return !(allFieldsFrozen && allFieldsWithoutPossibleValues)
    },
    freezeText() {
      return Boolean(this.serverDefinedFrozenFields?.text);
    },
    freezeFont() {
      return this.serverDefinedFrozenFields?.font ||
          this.templateControl?.freeze?.[FREEZABLE.font] ||
          false;
    },
    freezeWeight() {
      return this.serverDefinedFrozenFields?.weight ||
          this.templateControl?.freeze?.[FREEZABLE.weight] ||
          false;
    },
    freezeColor() {
      return this.serverDefinedFrozenFields?.color ||
          this.templateControl?.freeze?.[FREEZABLE.color] ||
          false;
    },
    possibleTextValues() {
      return this.possibleValues?.[KEY_VALUE] || this.inTemplatePossibleValues?.[KEY_TEXT] || [];
    },
    possibleFontValues() {
      return this.possibleValues?.[KEY_FONT] || this.inTemplatePossibleValues?.[KEY_FONT] || [];
    },
    possibleColorValues() {
      return this.possibleValues?.[KEY_COLOR] || this.inTemplatePossibleValues?.[KEY_COLOR] || [];
    },
    hasPossibleColorValues() {
      // @Fix: If possible color values has groups - it will be an object then:
      if(Array.isArray(this.possibleColorValues)) {
        return this.possibleColorValues.length > 0;
      }
      return Object.keys(this.possibleColorValues).length > 0;
    }
  },
  methods: {
    handleInputChange(key, eventValue) {
      let value = eventValue
      // @Business: [data-hide] attribute handling:
      if(key === KEY_VALUE && this.dataHideParser) {
        this.dataHideParser.text = eventValue
        value = this.dataHideParser.fullText
      }
      this.$emit('change', { type: 'text', id: this.identifier, key, value });
    },
    handleInputReset(key) {
      this.$emit('reset', { type: 'text', id: this.identifier, key });
    },
    handleOptionSelectedFromDropdown(text) {
      // this code must be repeated to make new Wrapper instance each time new option is selected via dropdown:
      this.dataHideParser = new DataHideHtmlTextWrapper('');
      // we need to fire input change because "select" event was before input event:
      this.handleInputChange(KEY_VALUE, text)
    }
  },
  watch: {
    settings: {
      handler(newValue) {
        Object.entries(newValue || {})
            .forEach(([key, value]) => {
              if( key in this.text && this.text[key] !== value ) {
                this.text[key] = value;
              }
            });
        this.dataHideParser = new DataHideHtmlTextWrapper(this.text?.value || '');
        // !== undefined is on purpose (empty string is valid here !)
        if(this.text?.value !== undefined) {
          this.text.value = this.dataHideParser.text
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped lang="less">
.edition__font {
  display: flex;
  flex-wrap: wrap;

  .edition__font-color {
    margin-left: 0;
  }
}
</style>
