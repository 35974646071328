<template>
    <a-modal
        class="add-template"
        v-model="visible"
        title="Add templates"
        @ok="$emit('addTemplates')"
        @cancel="$emit('closeAddTemplateModal')"
        :width="1290"
    >
        <div class="add-template__sidebar">
            <div class="add-template__sidebar-header">
                <p class="add-template__sidebar-title">Categories</p>
                <a-input-search @input="handleCategorySearch($event)" class="add-template__sidebar-search" placeholder="Search"/>
            </div>
            <ul v-if="categories.length" class="add-template__categories scroll">
                <li
                    v-for="category in categories"
                    :key="category.id"
                    :class="{'is-selected': selectedCategoryId === category.id}"
                    class="add-template__category"
                    @click="handleSelectCategoryClick(category.id)"
                >
                    {{ category.name }}
                </li>
            </ul>
            <s-empty v-else/>
        </div>
        <div class="add-template__main">

                <a-tabs v-model="chosenType" @change="handleTabChanged()">
                    <a-tab-pane key="product" tab="Product">
                      <s-template-preview
                          @selected="selectTemp($event.id)"
                          :selected-templates="selectedTempsIds"
                          :templates="templates"
                          :lastSelectedTemplate="lastSelectedTemplate"
                      />
                    </a-tab-pane>
                    <a-tab-pane key="generic" tab="Generics">
                      <s-template-preview
                          @selected="selectTemp($event.id)"
                          :selected-templates="selectedTempsIds"
                          :templates="templates"
                          :lastSelectedTemplate="lastSelectedTemplate"
                      />
                    </a-tab-pane>
                    <a-input-search class="add-template__main-search" slot="tabBarExtraContent" @input="handleSearch($event)" placeholder="Search"/>
                    <a-dropdown slot="tabBarExtraContent">
                        <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                             <p class="add-template__main-sort">Sort by <a-icon type="down" /></p>
                        </a>
                        <a-menu slot="overlay">
                            <a-menu-item @click="sortBy('name')">
                              <p>Name <s-sort-icon fieldName="name" :currentField="orderBy.field" :direction="orderBy.dir" /></p>
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </a-tabs>

        </div>
        <template slot="footer">
            <a-button key="back" type="default" @click="$emit('closeAddTemplateModal')">
              Close
            </a-button>
            <a-button key="submit" type="primary" @click="$emit('addTemplates',selectedTempsIds)">
              Add selected
            </a-button>
        </template>

    </a-modal>
</template>

<script>
import { templateService } from '@/services/template.service'
import { debounce } from '@/view-helpers/debouncer'
import Empty from '@/components/layout/Empty'
import TemplatePreview from '@/components/links/components/TemplatePreview'
import SortIcon from '@/components/shared/SortIcon'
import { mapActions } from 'vuex'

export default {
    name: 'AddTemplate',
    components: {
        STemplatePreview: TemplatePreview,
        SEmpty: Empty,
        SSortIcon: SortIcon
    },
    data(){
        return {
          visible: true,
          lastSelectedTemplate: null,
          chosenType: 'product',
          templates: [],
          selectedTempsIds: [],
          searchTimeout: 0,
          searchQuery: '',
          orderBy: {field: 'name', dir: 'asc'},
          categories: [],
          selectedCategoryId: null,
          categorySearchTimeout: 0,
          searchCategoryQuery: ''
        }
    },
    mounted(){
        this.initFavouriteTemplates()
        this.loadTemplates()
        this.loadTemplateCategories()
    },
    beforeDestroy () {
      if(this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      if(this.categorySearchTimeout) {
        clearTimeout(this.searchTimeout);
      }
    },
    methods: {
        ...mapActions(['showAlertError']),
        ...mapActions('templates', ['initFavouriteTemplates']),
        async loadTemplates() {
          const { field, dir } = this.orderBy;
          const orderBy = [field, dir];
          try {
            this.templates = await templateService.all( {
              type: this.chosenType,
              name: this.searchQuery,
              category_id: this.selectedCategoryId !== 0 ? this.selectedCategoryId : null
            }, orderBy );
          } catch ( error ) {
            this.showAlertError('Template loading error:' + error.message);
            this.templates = [];
          }
          this.lastSelectedTemplate = null;
        },
        async loadTemplateCategories() {
          try {
            this.categories = await templateService.getCategories( { name: this.searchCategoryQuery } )
            this.categories.unshift({ id: 0, name: 'All' })
            this.selectedCategoryId = 0;
          } catch (error) {
            this.showAlertError('Category loading error:' + error.message);
          }
        },
        handleSelectCategoryClick(categoryId) {
          const isAlreadySelected = this.selectedCategoryId === categoryId;
          this.selectedCategoryId = isAlreadySelected ? null : categoryId;
          this.loadTemplates()
        },
        handleTabChanged() {
          this.loadTemplates();
        },
        sortBy(field) {
          const toggleDir = this.orderBy.dir === 'asc' ? 'desc' : 'asc';
          this.orderBy = {field, dir: toggleDir};
          this.loadTemplates();
        },
        selectTemp(templateId){
            const index = this.selectedTempsIds.indexOf(templateId);
            if(index === -1) {
              this.selectedTempsIds.push(templateId);
              this.lastSelectedTemplate = this.templates.find(t => t.id === templateId);
            } else {
              this.selectedTempsIds.splice(index, 1);
              this.lastSelectedTemplate = null;
            }
        },
        handleSearch: debounce(async function (timer, { target }) {
          this.searchTimeout = timer;
          this.searchQuery = target.value;
          await this.loadTemplates()
        }),
        handleCategorySearch: debounce(async function (timer, { target }) {
          this.categorySearchTimeout = timer;
          this.searchCategoryQuery = target.value;
          await this.loadTemplateCategories()
        })
    }
}
</script>

<style lang="less">
    .add-template {

        .ant-modal {
            top:48px;
        }

        .ant-modal-wrap {
            overflow: hidden;
        }


        .ant-modal-body {
            display: flex;
            align-items: stretch;
            padding: 0;
            height: 100%;
        }

        &__sidebar {
            flex-shrink: 0;
            position: relative;
            width: 233px;
            background-color: @gray-2;
            border-right: solid 1px @gray-4;

            &-header {
                padding: 16px 24px;
                border-bottom: solid 1px @gray-4;
            }

            &-title {
                margin-bottom: 8px;
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                color: @gray-10;
            }

            &-search {

            }
        }

        &__categories {
            max-height: calc(100vh - 96px - 200px + 64px - 97px);
        }

        &__category {
            display: flex;
            align-items: center;
            padding: 0 24px;
            height: 40px;
            line-height: 40px;
            opacity: 0.65;
            cursor: pointer;
            &:hover {
                opacity: 1;
            }
            &.is-selected{
                background-color: @primary-6;
                opacity: 1;
            }
        }

        &__temps {
            flex-shrink: 0;
            height: 100%;
            max-height: calc(100vh - 96px - 200px);

            width: 600px;
            background-color: @gray-1;
            border-right: solid 1px @gray-4;

            &-empty {
                margin: 0;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            padding: 16px;
        }

        &__main {
            width: calc(100% - 233px);
            // padding: 0 16px;
            &-search {
                width: 477px;

            }
            &-sort {
                margin-left: 32px;
                font-weight: 400;
                color: @gray-9;
                font-size: 14px;
            }
            .ant-tabs {
                height: 100%;
            }
            .ant-tabs-content {
                height: 100%;
            }

            .ant-tabs-extra-content {
                display: flex;
                align-items: center;
                line-height: 64px;
                margin-top: 0;
            }

            .ant-tabs-bar {
                padding: 0 16px;
                margin: 0;
                background-color: @gray-2;
                border-bottom: none;
            }

            .ant-tabs-nav-container {
                font-size: 16px;
                line-height: 40px;
            }
        }

        &__content {
            display: flex;
            height: 100%;
        }

        &__preview {
            width: 100%;
            max-height: calc(100vh - 96px - 200px);
            padding: 16px;
            &-img {
                width: 100%;
                margin-bottom: 16px;
                background-size: contain;
                background-repeat: no-repeat;
            }
            &-empty {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                color: @gray-7;
            }
        }



    }
</style>
