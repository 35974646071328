import { EllipsisDirective } from '@/components/shared/ellipsis.directive';
import { userSessionService } from '@/services/user-session.service'
import { createPinia, PiniaVuePlugin } from 'pinia';
import Vue from 'vue'
import VueRouter from 'vue-router';
import store from './store'
import {
	Tabs,
	Tag,
	ConfigProvider,
	Table,
	Upload,
	Select,
	Divider,
	Checkbox,
	Empty,
	Dropdown,
	Badge,
	Menu,
	Modal,
	Spin,
	Alert,
	Icon,
	Row,
	Col,
	Avatar,
	Layout,
	Button,
	Input,
	InputNumber,
	Slider,
	Form,
	FormModel,
	Popconfirm,
	Popover,
	DatePicker,
	Tooltip,
	Pagination,
	Radio,
	Collapse
} from 'ant-design-vue';
import App from './App.vue'
import { router } from '@/routes'
import ElementQueries from 'css-element-queries/src/ElementQueries'

import '@/less/main.less'
ElementQueries.listen()

Vue.use(VueRouter)
	.use(Tabs)
	.use(Tag)
  .use(ConfigProvider)
  .use(Table)
  .use(Upload)
	.use(Select)
	.use(Divider)
	.use(Checkbox)
	.use(Empty)
	.use(Dropdown)
	.use(Badge)
	.use(Menu)
	.use(Modal)
	.use(Spin)
	.use(Alert)
	.use(Icon)
	.use(Col)
	.use(Row)
	.use(Avatar)
	.use(Layout)
	.use(Button)
	.use(Input)
	.use(InputNumber)
	.use(Slider)
	.use(Form)
	.use(FormModel)
	.use(Popconfirm)
	.use(DatePicker)
	.use(Tooltip)
	.use(Pagination)
	.use(Radio)
	.use(Popover)
	.use(Collapse)
	.use(EllipsisDirective)

Vue.config.productionTip = false

userSessionService.observeLogIn(store);

Vue.use(PiniaVuePlugin);
new Vue({
	render: (h) => h(App),
	router,
	store,
	pinia: createPinia(),
}).$mount('#app')

// @Fix => init() need to be always run after window load event [!]
window.addEventListener('load', () => ElementQueries.init())
