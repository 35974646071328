import { userService } from '@/services/user.service'

const DEFAULT_USER = {
	id: 0,
	email: '',
	name: '',
	lastName: '',
	token: '',
	refreshToken: '',
	roles: [],
	permissions: [],
	registrationSent: false
}

export const USER_ROLES = Object.freeze({
	USER: 'user',
	EDITOR: 'editor',
	TEMPLATE_EDITOR: 'templateEditor',
	ADMIN: 'admin',
	CIM_EXPORTER: 'exportToCim', //obsolete
});

export const MAPPED_USER_ROLES = Object.freeze({
	[USER_ROLES.USER]: 'User',
	[USER_ROLES.EDITOR]: 'Editor',
	[USER_ROLES.TEMPLATE_EDITOR]: 'Template Editor',
	[USER_ROLES.ADMIN]: 'Admin',
	[USER_ROLES.CIM_EXPORTER]: 'CIM Exporter',
})

const PERMISSIONS = Object.freeze({
	OWN_PROJECT_CREATE: 'own-project-create',
	OWN_PROJECT_EDIT: 'own-project-edit',
	OWN_PROJECT_REMOVE: 'own-project-remove',
	OWN_PROJECT_COPYING_FOR_OTHERS: 'own-project-copy-for-others',
	CIM_EXPORT: 'allow-export-to-cim',
	TEMPLATE_CREATE: 'template-create',
	TEMPLATE_EDIT: 'template-edit',
	TEMPLATE_DEACTIVATE: 'template-deactivate',
	TEMPLATE_REMOVE: 'template-remove',
	TEMPLATE_TEST_READ: 'template-test-read',
	TEMPLATE_TEST_PUBLISH: 'template-test-publish',
	REPORTS_READ: 'reports-read',
	USER_READ: 'user-read',
	USER_CREATE: 'user-create',
	USER_EDIT: 'user-edit',
	USER_DELETE: 'user-delete',
	GALLERY_READ: 'gallery-read',
	GALLERY_CREATE: 'gallery-create',
	GALLERY_ITEM_ARCHIVE: 'gallery-item-archive',
	GALLERY_ITEM_REMOVE: 'gallery-item-remove',
	GALLERY_CAMPAIGN_CREATE_EDIT: 'gallery-campaign-create-edit',
	GALLERY_CAMPAIGN_ARCHIVE: 'gallery-campaign-archive',
});

export default {
	state: {
		...DEFAULT_USER
	},
	namespaced: true,
	getters: {
		userInfo: ({name, lastName, email, roles }) => ({name, lastName, email, roles}),
		isAuth: s => Boolean(s.email && s.token),
		userRoles: ({roles}) => (roles || []),
		userPermissions: ({permissions}) => permissions,
		// @Business: all permissions helper + parametrized getter to check current user permissions
		allPermissions: () => PERMISSIONS,
		hasPermission: ({permissions = []}) => (permissionName = '') => permissions.includes(permissionName),
		isRegistrationFormSent: ({registrationSent}) => registrationSent,
	},
	mutations: {
		AUTHORIZE_USER( state, {id, token, refreshToken, email, roles, permissions}) {
			state.id = id;
			state.token = token;
			state.refreshToken = refreshToken;
			state.email = email;
			state.roles = roles;
			state.permissions = permissions;
		},
		SET_USER_INFO(state, {email, name, lastName, roles}) {
			state.email = email;
			state.name = name;
			state.lastName = lastName;
			state.roles = roles;
		},
		MARK_REGISTRATION_FORM_SENT(state) {
			state.registrationSent = true;
		}
	},
	actions: {
		signUp({ commit, dispatch }, {id, token, refreshToken, email, roles, permissions}) {
			commit('AUTHORIZE_USER', {id, token, refreshToken, email, roles, permissions});
			dispatch('showAlertSuccess', 'User successfully registered', {root: true});
		},
		logIn({ commit, dispatch }, {id, token, refreshToken, email, roles, permissions}) {
			commit('AUTHORIZE_USER', {id, token, refreshToken, email, roles, permissions});
			dispatch('showAlertSuccess', 'User successfully logged in', {root: true});
		},
		logOut({commit}) {
			commit('AUTHORIZE_USER', {...DEFAULT_USER});
		},
		markRegistrationSent({commit, dispatch}) {
			commit('MARK_REGISTRATION_FORM_SENT')
			dispatch('showAlertSuccess', 'Registration link - sent, check your e-mail', {root: true});
		},
		async loadUserInfo({state, commit}) {
			const {email, name, last_name: lastName, roles} = await userService.loadInfo(state.id);
			commit('SET_USER_INFO', {email, name, lastName, roles });
		},
		async saveUserInfo({state, commit, dispatch}, userInfo) {
			await userService.saveInfo(state.id, userInfo);
			commit('SET_USER_INFO', {email: state.email, ...userInfo});
			dispatch('showAlertSuccess', 'User data - updated', {root: true});
		}
	}
};
