import { MOVABLE_CONTROL_BTN } from '@/templating/movables/control-types';
import { addButton } from '@/templating/movables/movable-gui-helpers'

function setBtnPosition(btn, x, y) {
	btn.style.left = `${x - 50}px`;
	btn.style.top = `${y}px`;
}

export function decorateMovableWithBtnControl( {movable, container, events, iconColor, targetName}) {
	const { target } = movable;
	const {x: ox, y: oy} = container.getBoundingClientRect();
	const {x, y} = target.getBoundingClientRect();
	const editBtn = addButton({startX: x-ox, startY: y-oy, targetName, iconColor, typeId: MOVABLE_CONTROL_BTN.movableFrameToggle});
	const updateBtnPosition = (target) => {
		const {x, y} = target.getBoundingClientRect()
		setBtnPosition(editBtn, x-ox, y-oy)
	}
	container.appendChild(editBtn)
	for(const event of events) {
		movable.on(event, ({target}) => updateBtnPosition(target))
	}
	const btnIcon = editBtn.querySelector('svg')
	editBtn.addEventListener('click', () => {
		const { style } = movable.innerMoveable.moveable.moveable.controlBox.getElement();
		const isShown = style.display === 'block' || !style.display;
		style.display = isShown ? 'none' : 'block';
		btnIcon.setAttribute('fill', isShown ? 'currentColor' : iconColor);
		editBtn.blur();
	})
	// Some monkeys patch:
	const originalDestroy = movable.destroy.bind(movable);
	movable.destroy = function() {
		editBtn.remove();
		originalDestroy();
	}
	const originalUpdateRect = movable.updateRect.bind(movable);
	movable.updateRect = function() {
		originalUpdateRect();
		updateBtnPosition(target);
	}
}
