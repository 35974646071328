<template>
    <div class="clearfix">
        <a-row>
            <a-col
                :xs="colSpaceForImage"
                v-for="file in unwrappedFileList"
                :key="file.uid"
            >
                <div
                    class="img_preview"
                    @mouseenter="showImgOverflowUID = file.uid"
                    @mouseleave="showImgOverflowUID = ''"
                    :title="file.computedName"
                    :set="(fileStatus = getFileStatus(file))"
                >
                    <div
                        class="img_preview-filename"
                        v-if="
                            file.computedName && showImgOverflowUID === file.uid
                        "
                    >
                        {{ file.computedName }}
                    </div>
                    <div
                        class="img_overflow"
                        v-if="showImgOverflowUID === file.uid"
                    >
                        <a-button
                            v-if="
                                fileStatus !== 'EXPIRED' &&
                                    !file.isArchived &&
                                    canSelect
                            "
                            class="img_overflow__button"
                            type="ghost"
                            icon="check"
                            @click="handleChoose(file)"
                        />
                        <a-button
                            v-if="!file.isNotRemovable"
                            class="img_overflow__button"
                            type="ghost"
                            icon="delete"
                            @click="handleRemove(file)"
                        />
                    </div>
                    <img
                        :src="file.url"
                        :alt="`Image ${file.computedName || ''} preview`"
                        :style="imgComputedStyle"
                    />
                    <div class="img_icon_type">
                        <a-icon
                            type="picture"
                            v-if="file.type === 'GALLERY_ITEM'"
                        />
                        <a-icon type="edit" v-if="file.type === 'FILE'" />
                    </div>
                    <div class="img_icon_expires">
                        <icon-expiration-for-file :file="file" />
                    </div>
                </div>
            </a-col>
            <a-col :xs="colSpaceForImage" v-if="canUpload && canUploadMore">
                <a-upload
                    ref="uploader"
                    list-type="picture-card"
                    :multiple="true"
                    :file-list="[]"
                    :customRequest="() => {}"
                    accept="image/jpeg,image/png,image/svg+xml"
                    @change="({ file }) => $emit('uploaded', file)"
                >
                    <div>
                        <a-icon type="upload" />
                        <div class="ant-upload-text">
                            Upload
                        </div>
                    </div>
                </a-upload>
            </a-col>
            <a-col
                v-if="canAddFromGallery"
                style="padding: 10px; height: 104px; display: flex; align-items: center; gap: 10px; justify-content: center"
                :gutter="[16, 16]"
                :xs="colSpaceForGalleryBtn"
            >
                <span v-if="canUpload && canUploadMore">or</span>
                <a-button
                    @click="
                        chosenCampaignId = 0
                        showFormFotosUploaderAddFromGalleryModal = true
                    "
                >
                    <a-icon type="plus" /> Add from gallery
                </a-button>
            </a-col>
        </a-row>
        <form-fotos-uploader-add-from-gallery-modal
            v-if="showFormFotosUploaderAddFromGalleryModal"
            v-model="showFormFotosUploaderAddFromGalleryModal"
            :selection-type="gallerySelectionType"
            :campaign-id="chosenCampaignId"
            :gallery-title="galleryTitle"
            @addItemsFromGallery="handleAddFromGallery($event)"
            @addCampaigns="handleAddCampaigns($event)"
        />
    </div>
</template>

<script>
import FormFotosUploaderAddFromGalleryModal from '@/components/project/components/form/photos/FormFotosUploaderAddFromGalleryModal.vue'
import { galleryInProjectService } from '@/components/gallery/service/gallery-in-project.service'
import { hasGalleryItemExpired } from '@/components/gallery/model/gallery-item'
import IconExpirationForFile from '@/components/gallery/components/IconExpirationForFile.vue'
import { galleryService } from '@/services/gallery.service'

const MAX_LIMIT_OF_PHOTOS = 8
const isRemovable = file => !file.isNotRemovable
const isNotFileOrEmptyType = file => file.type !== 'FILE' && !file.type

export default {
    name: 'FormPhotosUploader',
    components: { IconExpirationForFile, FormFotosUploaderAddFromGalleryModal },
    props: {
        fileList: {
            type: Array,
            default: () => [],
        },
        maxPhotos: {
            type: Number,
            default: MAX_LIMIT_OF_PHOTOS,
        },
        canUpload: {
            type: Boolean,
            default: true,
        },
        canAddFromGallery: {
            type: Boolean,
            default: true,
        },
        canSelect: {
            type: Boolean,
            default: true,
        },
        hFlipPhotos: {
            type: Boolean,
            default: false,
        },
        gallerySelectionType: {
            type: String,
            default: 'GALLERY',
        },
        liveCampaignsAsFolders: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showFormFotosUploaderAddFromGalleryModal: false,
            showImgOverflowUID: '',
            chosenCampaignId: undefined,
            galleryItemsInfo: {},
            unwrappedFileList: this.fileList,
        }
    },
    computed: {
        compactUi() {
            // show compact Ui for single creation view
            return this.$route.name === 'project-creation'
        },
        colSpaceForImage() {
            return this.compactUi ? 8 : 3
        },
        colSpaceForGalleryBtn() {
            return this.compactUi
                ? 16
                : this.unwrappedFileList.length &&
                  this.unwrappedFileList.length % 6 === 0
                ? 7
                : 6
        },
        galleryTitle() {
            return this.chosenCampaignId ? 'Campaign' : undefined
        },
        numberOfUploadedPhotos() {
            // Uploaded photos are removable and does not have a type - or their type is "FILE";
            return this.unwrappedFileList
                .filter(isRemovable)
                .filter(isNotFileOrEmptyType).length
        },
        canUploadMore() {
            return this.numberOfUploadedPhotos < this.maxPhotos
        },
        imgComputedStyle() {
            return this.hFlipPhotos ? { transform: 'scaleX(-1)' } : {}
        },
    },
    methods: {
        handleChoose(file) {
            this.$emit('switch', file)
        },
        handleRemove(file) {
            // Remove file, but also send only removable list!
            const removedFromList = this.unwrappedFileList
                .filter(f => f !== file)
                .filter(isRemovable)
            this.$emit('removed', { file, fileList: removedFromList })
        },
        handleAddFromGallery(galleryItemsIds) {
            this.$emit('fromGallery', galleryItemsIds)
            // close modal:
            this.showFormFotosUploaderAddFromGalleryModal = false
        },
        handleAddCampaigns(campaignIds) {
            this.$emit('fromCampaigns', campaignIds)
            // close modal:
            this.showFormFotosUploaderAddFromGalleryModal = false
        },
        getFileStatus(file) {
            return file.expiresAt === undefined
                ? ''
                : hasGalleryItemExpired(file.expiresAt)
        },
        async extractCampaigns() {
            // All possible values as campaigns should be "extracted"
            // IT MUST BE done like this (dynamically) to have file list "up to date"
            const campaigns = this.fileList.filter(
                ({ type }) => type === galleryInProjectService.fileType.CAMPAIGN
            )
            const nonCampaigns = this.fileList.filter(
                ({ type }) => type !== galleryInProjectService.fileType.CAMPAIGN
            )
            const mappedPromises = campaigns.map(({ uid }) => {
                const [, campaignId] = uid.split('/')
                return galleryService.getAllForCampaign(campaignId).call()
            })
            let unwrappedFiles = []
            try {
                unwrappedFiles = (await Promise.all(mappedPromises))
                    .flatMap(({ results }) => results)
                    .map(galleryInProjectService.mapCampaignItemToFileInfo)
            } catch (e) {
                console.log('Unable to extractCampaigns')
                console.error(e)
            }
            this.unwrappedFileList = [...unwrappedFiles, ...nonCampaigns]
        },
    },
    watch: {
        fileList: {
            handler(files, oldFiles) {
                // CRUCIAL to not end up with infinite loops:
                const isUpdated =
                    JSON.stringify(files) !== JSON.stringify(oldFiles)
                if (isUpdated && this.liveCampaignsAsFolders === false) {
                    this.extractCampaigns()
                } else if (isUpdated) {
                    // @fix: change unwrappedFileList only if its actually updated somehow (to avoid GUI shown mistakes)
                    this.unwrappedFileList = files
                }
            },
            immediate: true,
        },
    },
}
</script>

<style scoped lang="less">
.img_preview {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    max-width: 104px;
    height: 104px;
    padding: 8px;
    border: 1px solid @gray-5;
    border-radius: 4px;
    margin-bottom: 1em;

    .img_icon_expires {
        position: absolute;
        bottom: 5px;
        right: 5px;
    }

    .img_icon_type {
        position: absolute;
        bottom: 6px;
        left: 6px;
        width: 20px;
        height: 20px;
        // ? shadow ?
    }

    .img_overflow {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        background-color: rgba(0, 0, 0, 0);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: background-color 500ms;

        &__button {
            border: 0;
        }
    }

    .img_overflow:hover {
        background-color: rgba(0, 0, 0, 0.6);
    }

    .img_preview-filename {
        position: absolute;
        top: 6px;
        left: 8px;
        right: 8px;
        background: rgba(0, 0, 0, 0.7);
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 6px;
        font-size: 12px;
        line-height: 12px;
        height: 24px;
        white-space: nowrap;
        z-index: 3;
    }

    &:hover {
        border-color: @gray-8;
    }

    img {
        width: 100%;
        height: auto;
        max-height: 88px;
        object-fit: contain;
        border-radius: 2px;
    }
}
</style>
