var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"graphics box",class:{
            'disable-safe-area': !_vm.safeArea,
        }},[_c('div',[_c('div',{staticClass:"box__title"},[_vm._m(0),_c('div',{staticClass:"box_resize_buttons"},[_c('scale-stepper',{attrs:{"min":0.1,"max":1},model:{value:(_vm.scale),callback:function ($$v) {_vm.scale=$$v},expression:"scale"}})],1),(_vm.hasAnyCreations)?_c('a-button',{attrs:{"type":"default"}},[_c('a-dropdown',[_c('a',{staticClass:"ant-dropdown-link",on:{"click":function($event){$event.preventDefault();}}},[_vm._v(" Export "),_c('a-icon',{attrs:{"type":"down"}})],1),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{staticStyle:{"position":"relative"},attrs:{"disabled":!_vm.isDownloadForSelectedEnabled,"title":_vm.unableToExportTitle},on:{"click":function($event){return _vm.$emit('exportSelectedGraphics', {
                                        creationModels: _vm.allSelectedCreations,
                                    })}}},[_c('p',[_vm._v(" Selected: "+_vm._s(_vm.allSelectedCreationsCount > 0 ? _vm.allSelectedCreationsCount : '')+" (local download) ")])]),(_vm.hasPermission(_vm.allPermissions.CIM_EXPORT))?_c('a-menu-item',{staticStyle:{"position":"relative"},attrs:{"disabled":!_vm.isDownloadForSelectedEnabled,"title":_vm.unableToExportTitle},on:{"click":function($event){return _vm.$emit('exportSelectedGraphics', {
                                        creationModels: _vm.allSelectedCreations,
                                        exportToCim: true,
                                    })}}},[_c('p',[_vm._v(" Selected: "+_vm._s(_vm.allSelectedCreationsCount > 0 ? _vm.allSelectedCreationsCount : '')+" (send to CIM) ")])]):_vm._e(),_c('a-menu-item',{on:{"click":function($event){return _vm.$emit('exportAllCreations', {
                                        creationModels: _vm.banners,
                                    })}}},[_c('p',[_vm._v("All banners (local download)")])])],1)],1)],1):_vm._e()],1),(_vm.loading)?_c('div',{staticClass:"box__empty"},[_c('s-preloader',{attrs:{"text":"Loading banners..."}})],1):(!_vm.hasAnyCreations)?_c('div',{staticClass:"box__empty"},[_c('p',[_vm._v("Select a binding to begin")])]):[_c('div',{staticClass:"graphics__search"},[_c('a-select',{attrs:{"mode":"tags","placeholder":"Search by size","allowClear":true},on:{"change":_vm.searchSize},model:{value:(_vm.selectedSizes),callback:function ($$v) {_vm.selectedSizes=$$v},expression:"selectedSizes"}},_vm._l((_vm.allSizes),function(size){return _c('a-select-option',{key:size},[_vm._v(" "+_vm._s(size)+" ")])}),1),_c('a-button',{staticClass:"safe-area",on:{"click":function($event){_vm.safeArea = !_vm.safeArea}}},[_vm._v("Safe area")]),_c('a-button',{staticClass:"new-sizes",on:{"click":function($event){_vm.showProjectSizesModal = true}}},[_c('a-icon',{attrs:{"type":"pic-center"}}),_vm._v(" Sizes ")],1)],1),_c('div',{staticClass:"graphics__checkbox-wrapper"},[_c('a-checkbox',{staticClass:"banner__checkbox",attrs:{"indeterminate":_vm.indeterminateSelection},on:{"change":function($event){return _vm.toggleAll()}},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}},[_vm._v(" Select all "+_vm._s(_vm.selectionStats)+" ")])],1),_c('ul',{ref:"allCreations",staticClass:"box__main scroll"},_vm._l((_vm.filteredSelectableBanners),function(creation){return _c('li',{key:creation.id,staticClass:"banner"},[_c('div',{staticClass:"banner__header",attrs:{"title":creation.isDisabled
                                    ? 'Can\'t select caused by some expired /  deleted galley item(s) used on this creation'
                                    : ''}},[_c('a-checkbox',{staticClass:"banner__checkbox",attrs:{"checked":_vm.isCreationIdSelected(
                                        _vm.connectionId,
                                        creation.id
                                    ),"disabled":creation.isDisabled},on:{"change":function($event){return _vm.toggleSelect(creation)}}},[_vm._v(" "+_vm._s(creation.name)+" | "+_vm._s(creation.size.name)+" ")]),_c('creation-expire-image-indicator',{attrs:{"creation-settings":creation.settings,"creation":creation}}),_c('a-popconfirm',{attrs:{"title":"Set this banner as project thumbnail?","ok-text":"Yes","cancel-text":"Cancel"},on:{"confirm":function($event){return _vm.makeBannerProjectPreview(creation)}}},[_c('a-button',{staticClass:"banner__btn",staticStyle:{"margin-right":"3px"},attrs:{"title":"Set as project thumbnail","type":_vm.calculatePreviewButtonType(creation.id)}},[_c('a-icon',{attrs:{"type":"picture"}})],1)],1),_c('a-button',{staticClass:"banner__btn",attrs:{"title":"Open in Single Creation Editor"},on:{"click":function($event){return _vm.editBanner(creation)}}},[_c('a-icon',{attrs:{"type":"edit"}})],1)],1),_c('div',{staticClass:"banner__image scroll",attrs:{"data-creation-id":creation.id}},[_c('template-holder',{attrs:{"creationModel":creation,"transform-when-ready":_vm.creationTransform},on:{"transformApplied":function($event){return _vm.recalculateHeight(creation.id, $event)}}})],1)])}),0)]],2),[_c('ProjectSizesModal',{attrs:{"visible":_vm.showProjectSizesModal},on:{"closeProjectSizesModal":function($event){_vm.showProjectSizesModal = false}}})]],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box__actions"},[_c('h4',[_vm._v("Graphics")])])}]

export { render, staticRenderFns }