var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","margin-top":"5em"}},[_c('inline-preloader',{attrs:{"text":_vm.loadingText}})],1):_c('div',{staticClass:"scroll",staticStyle:{"margin":"10px 0"}},[_c('a-table',{staticClass:"gallery_table",attrs:{"columns":_vm.columns,"data-source":_vm.galleryItems,"pagination":_vm.paginationObj,"row-key":function (record) { return record.id; },"row-selection":_vm.rowSelection,"locale":{
          emptyText: _vm.queryText ? ("No items found for serach criteria ' " + _vm.queryText + " '") : 'No data'
        },"size":"small"},on:{"change":_vm.handlePaginationChange},scopedSlots:_vm._u([{key:"image",fn:function(ref){
        var name = ref.name;
        var url = ref.url;
return [_c('div',{staticClass:"thumbnail"},[_c('div',{staticClass:"thumbnail_img",style:({ backgroundImage: _vm.resolveGalleryImageUrl(url) })}),_c('div',{staticClass:"thumbnail_text"},[_vm._v(_vm._s(name))])])]}},{key:"tags",fn:function(tags){return _vm._l((tags),function(ref){
        var name = ref.name;
return _c('a-tag',{key:name,attrs:{"color":"#108ee9"}},[_vm._v(" "+_vm._s(name)+" ")])})}},{key:"expires",fn:function(expires){return [_c('expiration-marker',{attrs:{"expired-at":expires.at}})]}},{key:"action",fn:function(galleryItem){return [_c('div',{staticClass:"actions"},[(_vm.canEdit && _vm.hasPermission(_vm.allPermissions.GALLERY_CREATE))?_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.handleItemEdit(galleryItem)}}},[_vm._v(" Edit ")]):_vm._e(),(_vm.hasPermission(_vm.allPermissions.GALLERY_ITEM_ARCHIVE))?_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.handleItemArchive(galleryItem)}}},[_vm._v(" "+_vm._s(_vm.archiveActionName)+" ")]):_vm._e(),(_vm.hasPermission(_vm.allPermissions.GALLERY_ITEM_REMOVE))?_c('a-popconfirm',{attrs:{"title":"Are you sure remove this graphic?","ok-text":"Yes","cancel-text":"No"},on:{"confirm":function($event){return _vm.handleItemRemove(galleryItem)}}},[_c('a',[_vm._v(" Remove ")])]):_vm._e()],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }