<template>
  <a-modal
      :visible="visible"
      title="List of additional sizes"
      @cancel="closeModal()"
  >
    <div>
      <div v-if="projectSizes.length > 0">
        <a-tag
            @close="handleRemoveSize($event, id)"
            class="size_tag"
            :closable="true"
            v-for="{id, name, cta_type} of projectSizes" :key="id"
        >
          {{name}} {{renderCtaType(cta_type)}}
        </a-tag>
      </div>
      <a-empty v-else description="" :image="PRESENTED_IMAGE_SIMPLE"/>
      <a-divider />
      <a-form-model ref="formRef" :model="sizeForm" :rules="rules">
        <p>New size + CTA:</p>
          <div style="display: flex; justify-content: space-between">
            <a-form-model-item prop="newSize"  style="width: 100%">
              <a-input
                  @pressEnter="saveNewSize()"
                  ref="sizeBox"
                  data-testid="newSize"
                  v-model="sizeForm.newSize"
              />
            </a-form-model-item>
            <a-form-model-item prop="cta"  style="width: 50%">
              <a-select placeholder="No CTA" v-model="sizeForm.cta">
                <a-select-option
                    v-for="ctaType in ctaTypes"
                    :key="ctaType.value"
                    :value="ctaType.value"
                >
                  {{ ctaType.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item >
            <a-form-model-item style="margin-top: -1px">
              <a-button @click="saveNewSize()"> <a-icon type="plus" />Add </a-button>
            </a-form-model-item>
          </div>
      </a-form-model>
    </div>
    <template slot="footer">
      <a-button key="back" :type="hasChanges ? 'primary' : 'default'" @click="closeModal()">
        {{hasChanges ? 'Close' : 'Cancel'}}
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { CTA_MAPPER, CTA_TYPE } from '@/components/project/model/project-sizes';
import { Empty } from 'ant-design-vue';
import { projectService } from '@/services/project.service'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProjectSizesModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hasChanges: false,
      sizeForm: {
        newSize: '',
        cta: CTA_TYPE.NONE
      },
      rules: {
        newSize: [
          { required: true, message: 'This field is required in NxN format', trigger: 'change' },
          { validator: (rule, value, callback) =>  {
            if(value) {
              const sizeFormat = /^\d+x\d+$/;
              if(!sizeFormat.test(value)) {
                return callback('Size format: NxN where N is a number')
              }
            }
            callback();
          }}
        ],
        cta: [
          { required: true, message: 'This field is required' },
        ]
      }
    }
  },
  computed: {
    ...mapGetters('currentProject', ['projectId', 'projectSizes']),
    PRESENTED_IMAGE_SIMPLE: () => Empty.PRESENTED_IMAGE_SIMPLE,
    ctaTypes() {
      return [
        {name: 'No CTA', value: CTA_TYPE.NONE},
        {name: 'Desktop', value: CTA_TYPE.SEE_MORE},
        {name: 'Mobile (arrow)', value: CTA_TYPE.MOBILE_ARROW},
      ]
    }
  },
  watch: {
    visible(newVal) {
       if(newVal) {
         this.$nextTick(() => {
           this.$refs.sizeBox.focus();
         })
       }
    }
  },
  methods: {
    ...mapActions(['showAlertError']),
    ...mapActions('currentProject', ['addCustomSize', 'removeCustomSize']),
    ...mapActions('connectionTemplate', ['refreshCurrentConnection']),
    async closeModal() {
      this.$emit('closeProjectSizesModal');
      if(this.hasChanges) {
        this.hasChanges = false;
        await this.refreshCurrentConnection();
      }
    },
    renderCtaType(type) {
       const cta = CTA_MAPPER[type];
       return cta ? `(${cta})` : '';
    },
    saveNewSize() {
      this.$refs.formRef.validate(async (valid) => {
        if(!valid) {
          return;
        }
        const { newSize, cta } = this.sizeForm;
        try {
          const size = await projectService.addCustomSize(this.projectId, {dimensions: newSize, ctaType: cta})
          this.addCustomSize(size);
          this.$refs.formRef.resetFields();
          this.$refs.sizeBox.focus();
          this.hasChanges = true;
        } catch ( e ) {
          this.showAlertError(e.message);
        }
      })
    },
    async handleRemoveSize(ev, sizeId) {
      ev.preventDefault();
      try {
        const { result } = await projectService.removeCustomSize(this.projectId, sizeId)
        if(result === 'OK') {
          this.hasChanges = true;
          await this.removeCustomSize(sizeId);
        }
      } catch ( e ) {
        this.showAlertError(e.message);
      }
    }
  },
}
</script>

<style scoped>
    .has-error .ant-form-explain {
      margin-top: 0;
    }

    .size_tag {
      margin: 5px;
      color: black;
      line-height: 2em;
      font-size: 0.9em;
      font-weight: bolder;
      background-color: #e0601d;
      border-color: #8a3608;
    }
</style>
