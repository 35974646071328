import { decorateMovableWithBtnControl } from '@/templating/movables/movable-mover-contols'
import Moveable from 'moveable'
import { TControl } from '@/templating/TControl'
import { ownerWrapper } from '@/templating/movables/movable-gui-helpers'
import { fireOnWindowLoadEventOnce } from '@/templating/page-events/listeners'

export class TemplateMoveable extends TControl {
    static $selector = '[data-moveable]'
    static freezable = ['move']

    constructor(ref, container) {
        super(ref, 'moveable')
        this.container = container
        this.id = this.ref.getAttribute(TemplateMoveable.$selector.slice(1, -1))
    }

    get transform() {
        return getComputedStyle(this.ref).transform
    }
    set transform(value) {
        this.ref.style.transform = value
        this.updateMovableBox()
    }

    setupReactivity() {
        super.isReactive = true
        const ownerDiv = ownerWrapper()
        this.moveable = new Moveable(ownerDiv, {
            target: this.ref,
            draggable: true,
            origin: false,
            scalable: true,
            keepRatio: true,
        })

        const applyTransform = ({ target, transform }) =>
            (target.style.transform = transform)
        const dispatchTransform = ({
            target: {
                style: { transform },
            },
        }) => this.dispatch(this.id, 'transform', transform)
        this.moveable.on({
            drag: applyTransform,
            scale: applyTransform,
            dragEnd: dispatchTransform,
            scaleEnd: dispatchTransform,
        })

        decorateMovableWithBtnControl({
            movable: this.moveable,
            container: ownerDiv,
            events: ['drag', 'scale'],
            iconColor: '#4af',
            targetName: this.id,
        })

        // @Fix: movable need to be "refreshed" if load event happens after setupReactivity fired
        fireOnWindowLoadEventOnce(() => this.updateMovableBox())
    }

    updateMovableBox() {
        if (this.isReactive && this.moveable) {
            this.moveable.updateRect()
        }
    }

    onDestroy() {
        if (this.moveable) {
            this.moveable.destroy()
        }
    }
}
