<template>
  <div class="templateList">
    <div class="listHeader">
      <div class="listHeader__left">
        <h2>Templates </h2>
        <span class="listHeader__number">({{ templatesLength }})</span>
        <a-dropdown>
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            <p class="listHeader__sort">Sort by
              <a-icon type="down"/>
            </p>
          </a>
          <a-menu slot="overlay">
            <a-menu-item @click="sortBy('name')">
              <p>Name
                <sort-icon fieldName="name" :currentField="orderField" :direction="orderDir"/>
              </p>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <a-button
          v-if="hasPermission(allPermissions.TEMPLATE_CREATE)"
          @click.native="handleOpenNewTemplateModal"
          type="primary"
      >
        <a-icon type="plus" :style="{ width: '16px'}"/>
        New template
      </a-button>
    </div>
    <s-empty v-if="notFound" :size="'xl'"/>
    <a-tabs v-if="templates.length > 0" >
      <template v-for="({title, type, icon, elements}, idx) of templateLists">
        <a-tab-pane :key="idx" v-if="elements.length" style="margin-top: 0" >
          <div slot="tab">
            <h3 class="templateList__subtitle">
              <a-icon style="color: #d78b6e; margin-right: .45em" :type="icon" />
              {{title}}
              <a-badge
                  :count="elements.length"
                  :overflow-count="Infinity"
              />
            </h3>
          </div>
          <a-row class="scroll"
                 :key="title"
                 :gutter="[16,16]"
                 v-if="elements.length > 0"
                 style="height: 73vh; overflow-x: hidden; margin: 0"
          >
            <a-col v-for="template in elements" :key="template.id" :span="6">
              <temp-card
                  :temp="template"
                  :can-edit="hasPermission(allPermissions.TEMPLATE_EDIT)"
                  :can-deactivate="hasPermission(allPermissions.TEMPLATE_DEACTIVATE)"
                  :can-publish="hasPermission(allPermissions.TEMPLATE_TEST_PUBLISH)"
                  :can-remove="hasPermission(allPermissions.TEMPLATE_REMOVE)"
                  :is-favourite="isFavourite(template.id)"
                  @click.native="() => hasPermission(allPermissions.TEMPLATE_EDIT) && handleTemplateEdition(template)"
                  @deleteTemplate="handleTemplateDelete(template.id)"
                  @renameTemplate="handleRenameTemplate(template)"
                  @setDefaultValues="handleSetDefaultValuesForTemplate(template)"
                  @publishTemplate="handlePublishTemplate(template.id)"
                  @archiveTemplate="handleArchiveTemplate(template.id, true)"
                  @unArchiveTemplate="handleArchiveTemplate(template.id, false)"
                  @makrFavourite="handleMarkFavourite(template.id, $event)"
                  @downloadZip="handleDownloadAsZip(template)"
                  @cloneTemplate="handleClone(template)"
              />
            </a-col>
            <a-col v-if="hasPermission(allPermissions.TEMPLATE_CREATE) && !searchQuery && type === 1" :span="6">
              <empty-card @click.native="handleOpenNewTemplateModal()"/>
            </a-col>
          </a-row>
        </a-tab-pane>
      </template>
    </a-tabs>
    <rename-modal
        title="Rename template"
        fieldLabel="New template name"
        v-if="showRenameModal"
        :currentName="chosenTemplate && chosenTemplate.name"
        @closeRenameModal="showRenameModal = false"
        @whenNewName="handleNewNameGiven($event)"
    />

    <new-or-update-template-modal
        v-if="newTempModal"
        :template-data="templateToEdit"
        @closeNewTempModal="newTempModal=false"
        @onNewTemplateCreated="handleTemplateCreation($event)"
        @onTemplateUpdated="handleTemplateUpdate($event)"
    />

    <default-values-modal
        v-if="showDefaultValuesModal"
        :template="chosenTemplate"
        @closeDefaultValuesModal="showDefaultValuesModal=false"
    />

  </div>
</template>

<script>

import { Modal } from 'ant-design-vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { templateService } from '@/services/template.service';
import DefaultValuesModal from '@/components/templates/possible-values/PossibleValuesModal.vue';
import RenameModal from '@/components/main/components/RenameModal.vue';
import NewOrUpdateTemplateModal from './NewOrUpdateTemplateModal.vue';
import SortIcon from '@/components/shared/SortIcon.vue';
import Empty from '../layout/Empty.vue';
import EmptyCard from './EmptyCard.vue';
import TempCard from './TempCard.vue';

export default {
  name: 'TemplatesPage',
  components: {
    TempCard,
    EmptyCard,
    SEmpty: Empty,
    RenameModal,
    NewOrUpdateTemplateModal,
    DefaultValuesModal,
    SortIcon
  },
  data() {
    return {
      newTempModal: false,
      showRenameModal: false,
      showDefaultValuesModal: false,
      chosenTemplate: null,
      templateToEdit: {}
    };
  },
  computed: {
    ...mapState('templates', ['templates', 'searchQuery']),
    ...mapGetters('templates', ['orderField', 'orderDir', 'templatesLength', 'favouriteTemplates', 'isFavourite']),
    ...mapGetters('user', ['hasPermission', 'allPermissions']),
    notFound() {
      return this.searchQuery && this.templatesLength === 0;
    },
    templateLists() {
      return [
        {title: 'Test templates', type: 0, icon: 'experiment', elements: this.testTemplates},
        {title: 'Active templates', type: 1, icon: 'schedule', elements: this.activeTemplates},
        {title: 'Archived templates', type: 0, icon: 'container', elements: this.archivedTemplates},
        {title: 'Favourite templates', type: 0, icon: 'container', elements: this.favouriteTemplates},
      ]
    },
    testTemplates() {
      return this.templates.filter(t => t.status === 'TEST' || Boolean(t.is_test));
    },
    activeTemplates() {
      return this.templates.filter(t => t.status === 'ACTIVE' && !t.is_test);
    },
    archivedTemplates() {
      return this.templates.filter(t => t.status === 'INACTIVE');
    }
  },
  mounted() {
    this.initTemplates('').catch(() => {});
  },
  methods: {
    ...mapActions('templates', ['initTemplates', 'setOrder', 'renameTemplate', 'updateTemplate', 'markFavourite']),
    ...mapActions(['showAlertSuccess', 'showAlertError']),
    ...mapActions('uiState', ['showRemoveModalConfirm', 'showModalConfirm']),
    sortBy(fieldName) {
      const toggleDir = this.orderDir === 'asc' ? 'desc' : 'asc';
      this.setOrder({ fieldName, dir: toggleDir });
    },
    handleMarkFavourite(templateId, isFavourite) {
      this.markFavourite({templateId, isFavourite})
    },
    handleRenameTemplate(template) {
      this.chosenTemplate = template;
      this.showRenameModal = true;
    },
    handleSetDefaultValuesForTemplate(template) {
      this.chosenTemplate = template;
      this.showDefaultValuesModal = true;
    },
    handlePublishTemplate(templateId) {
      Modal.confirm({
        title: 'Publish the template?',
        content: '[WARNING !!!] Process is irreversible. Published template cannot be "Test" again',
        cancelText: 'Cancel',
        okText: 'Publish',
        icon: 'exclamation-circle',
        onOk: async () => {
          const template = await templateService.publish(templateId);
          this.updateTemplate(template);
        }
      });
    },
    handleNewNameGiven(name) {
      const { id } = this.chosenTemplate;
      this.renameTemplate({ id, name }).then(() => {
        this.showRenameModal = false;
      }).catch(() => {});
    },
    handleTemplateCreation(name) {
      this.newTempModal = false;
      this.showAlertSuccess(`New ${name} template added successfully `);
      this.initTemplates().catch(() => {});
    },
    handleTemplateUpdate(template) {
      this.newTempModal = false;
      if( template ) {
        this.updateTemplate(template);
      }
    },
    handleOpenNewTemplateModal() {
      this.templateToEdit = {};
      this.newTempModal = true;
    },
    handleTemplateEdition(template) {
      this.templateToEdit = template;
      this.newTempModal = true;
    },
    handleTemplateDelete(templateId) {
      this.showRemoveModalConfirm({
        title: 'Delete template?',
        content: '[WARNING !!!] If you delete a template you will lose all bindings in all projects where it was used '
            + '- even in projects belonging to other users!',
        okCallback: () => {
          this.removeTemplate(templateId);
        }
      });
    },
    handleArchiveTemplate(templateId, archive) {
      const contextAction = archive ? 'Archive' : 'Unarchive';
      const contextPerform = archive ? 'archived' : 'Unarchived';
      this.showModalConfirm({
        title: `${contextAction} template?`,
        content: `This template will be ${contextPerform}.`,
        okText: contextAction,
        okCallback: () => {
          this.archiveTemplate(templateId, archive);
        }
      })
    },
    async handleDownloadAsZip(template) {
      const { id, name, type, status, updated_at: updatedAt } = template
      try {
        await templateService.downloadAsZip(id, {name, updatedAt, type, status});
      } catch( e ) {
        this.showAlertError(e.message);
      }
    },
    async handleClone(template) {
      const { id } = template
      try {
        const {name} = await templateService.clone(id);
        this.showAlertSuccess(`Template cloned as test: ${name}`);
        this.initTemplates().catch(() => {});
      } catch( e ) {
        this.showAlertError(e.message);
      }
    },
    async archiveTemplate(templateId, markArchived) {
      try {
        await templateService.archive(templateId, markArchived);
        this.initTemplates().catch(() => {});
      } catch( e ) {
        this.showAlertError(e.message);
      }
    },
    async removeTemplate(templateId) {
      try {
        await templateService.remove(templateId);
        this.initTemplates().catch(() => {});
      } catch( e ) {
        this.showAlertError(e.message);
      }
    }
  }

};
</script>

<style lang="less">
.templateList {
  .card {
    &__img {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
    }
  }
  &__subtitle {
    color: @gray-9;
    font-size: 1.2rem;
  }
}
</style>
