<template>
    <div class="from__group" v-if="notFrozenAndWithoutPossibleValues">
        <inline-preloader v-if="loading" :text="loadingText" />
        <template v-else>
            <p class="form__label">{{ label }}</p>
            <slot></slot>
            <p class="form__desc">{{ description }}</p>
            <a-button v-if="canSetToEmpty" @click="$emit('emptyPhoto')">
                <a-icon type="file" /> Empty
            </a-button>
            <a-button
                v-if="canReset"
                style="margin-left: 4px"
                @click="$emit('resetPhoto')"
            >
                <a-icon type="undo" /> Reset to default
            </a-button>
            <a-button
                v-if="canHFlip"
                class="hFlip-btn"
                style="margin-left: 4px"
                @click="$emit('horizontalFlipPhoto', !isHFlipped)"
            >
                <a-icon
                    type="vertical-align-middle"
                    :style="{ color: isHFlipped ? '#e5580b' : '' }"
                />
                Flip
            </a-button>
            <form-photos-uploader
                style="margin-top: 8px"
                :fileList="fileListWithPossibleValues"
                :can-upload="!isFrozen"
                :can-add-from-gallery="!isGalleryAddFrozen && canAddFormGallery"
                :h-flip-photos="isHFlipped"
                @switch="$emit('switchPhoto', $event)"
                @removed="$emit('removePhoto', $event)"
                @uploaded="$emit('uploadPhoto', $event)"
                @fromGallery="$emit('addIdsFromGallery', $event)"
            />
        </template>
    </div>
</template>

<script>
import FormPhotosUploader from '@/components/project/components/form/photos/FormPhotosUploader.vue'
import { possibleValuesMixin } from '@/components/project/components/form/possible-values.mixin'
import InlinePreloader from '@/components/project/components/InlinePreloader.vue'
import { TemplateImage } from '@/templating/TemplateImage'
import { TemplatePhoto } from '@/templating/TemplatePhoto'
import { TemplateMask } from '@/templating/TemplateMask'
import { galleryInProjectService } from '@/components/gallery/service/gallery-in-project.service'
import { useGalleryCache } from '@/store/modules/gallery-cache.store'
import { mapActions, mapState } from 'pinia'

function determineType(url) {
    const [possiblePrefix] = url.split('/')
    if (possiblePrefix === '__gallery_campaign') {
        return galleryInProjectService.fileType.CAMPAIGN
    }
    return galleryInProjectService.fileType.FILE
}

function mapToNonRemovablePossibleValueFile(baseUrl, possibleMetadata) {
    return url => ({
        uid: url + '/possible',
        name: url,
        status: 'done',
        isNotRemovable: true,
        type: determineType(url),
        url: `${baseUrl}/${url}`,
        isArchived: possibleMetadata[url]?.status === 'INACTIVE',
        expiresAt: possibleMetadata[url]?.expiresAt,
        computedName: possibleMetadata[url]?.name,
    })
}

const KEY_URL = 'url'

export default {
    name: 'FormPhotos',
    components: {
        InlinePreloader,
        FormPhotosUploader,
    },
    props: {
        loading: {
            type: Boolean,
            default: true,
        },
        canReset: {
            type: Boolean,
            default: false,
        },
        canSetToEmpty: {
            type: Boolean,
            default: true,
        },
        canHFlip: {
            type: Boolean,
            default: false,
        },
        loadingText: {
            type: String,
            default: 'Loading images...',
        },
        placeholderId: String,
        imageType: String,
        fileList: {
            type: Array,
            default: () => [],
        },
        templateUrl: {
            type: String,
            required: true,
        },
        templateControl: {
            type: [TemplatePhoto, TemplateImage, TemplateMask],
        },
        label: String,
        description: String,
        serverDefinedFrozenFields: {
            default: () => ({}),
        },
        photoSettings: {
            default: () => ({}),
        },
        canAddFormGallery: {
            type: Boolean,
            default: true,
        },
        // serverDefinedPossibleValues from Mixin !
    },
    mixins: [possibleValuesMixin],
    computed: {
        isFrozen() {
            return Boolean(this.serverDefinedFrozenFields?.upload)
        },
        isGalleryAddFrozen() {
            return Boolean(this.serverDefinedFrozenFields?.gallery)
        },
        isHFlipped() {
            return Boolean(this.photoSettings?.hFlip)
        },
        possibleUrlValues() {
            return (
                this.possibleValues?.[KEY_URL] ||
                this.inTemplatePossibleValues?.[KEY_URL] ||
                []
            )
        },
        fileListWithPossibleValues() {
            return [
                ...this.possibleUrlValues.map(
                    mapToNonRemovablePossibleValueFile(
                        this.templateUrl,
                        this.possibleMetadata
                    )
                ),
                ...this.fileList,
            ]
        },
        // @Business: Control should NOT be displayed when it is FROZEN nad without possibleValues
        notFrozenAndWithoutPossibleValues() {
            return !(this.isFrozen && this.possibleUrlValues.length === 0)
        },
        possibleFileMetadataUrlIds() {
            return this.possibleUrlValues.filter(
                url =>
                    url.includes('__gallery_item/') ||
                    url.includes('__gallery_campaign/')
            )
        },
        ...mapState(useGalleryCache, {
            possibleMetadata(store) {
                return this.possibleFileMetadataUrlIds.length
                    ? store.getItemsByIds(this.possibleFileMetadataUrlIds)
                    : {}
            },
        }),
    },
    methods: {
        ...mapActions(useGalleryCache, ['assureFileIdsPresent']),
    },
    watch: {
        possibleUrlValues: {
            immediate: true,
            handler(newValues) {
                if (Array.isArray(newValues)) {
                    this.assureFileIdsPresent(this.possibleFileMetadataUrlIds)
                }
            },
        },
    },
}
</script>

<style lang="less">
.form__renew {
    margin-left: -5px;
    background: #191919;
}

.hFlip-btn {
    .anticon svg {
        rotate: 90deg;
    }
}
</style>
