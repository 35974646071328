import { MaxLenNormalizer } from '@/templating/normalizers/max-len-normalizer';
import { TControl } from '@/templating/TControl'
import { htmlPurifier } from './templating-helpers'

const DOM_RENDER_TIMEOUT = 100; // timeout for proper extraction of getComputedStyle

export class TemplateText extends TControl {

	static $selector = '[data-text]';
	static freezable = ['font', 'weight', 'color', 'italic', 'underline'];
	freezeAttr = 'data-text-freeze';
	freeze = {};
	normalizers = {}
	fullValue = ''; // hold value before normalization

	constructor (ref, container) {
		super(ref, 'text');
		this.id = this.ref.getAttribute(TemplateText.$selector.slice(1, -1));
		this.container = container;
		this.font = getComputedStyle(ref).fontFamily;
		this.setupFreezes();
		this.setupNormalizers();
	}

	setupFreezes() {
		const toFreeze = this.ref.getAttribute(this.freezeAttr);
		if(toFreeze) {
			const values = toFreeze.split(/[ ,]/).filter(Boolean);
			for(const val of values) {
				this.freeze[val] = true;
			}
		}
	}

	setupNormalizers() {
		const { maxLen } = this.ref.dataset // data-max-len
		if(maxLen) {
			this.normalizers.maxLen = new MaxLenNormalizer(this.ref, maxLen, this.langToken);
		}
	}

	changeLanguage(lang) {
		super.changeLanguage(lang);
		for(const normalizer of Object.values(this.normalizers)) {
			normalizer?.changeLanguage?.(this.langToken)
		}
		this.value = this.fullValue;
	}

	checkMaxLen() {
		this.normalizers?.maxLen?.fire();
	}

	get weight() {
		return this.ref.style.fontWeight;
	}
	set weight(value) {
		this.ref.style.fontWeight = value;
	}

	get transform() {
		return getComputedStyle(this.ref).transform;
	}
	set transform(value) {
		this.ref.style.transform = value;
	}

	get value() {
		return this.ref.innerHTML;
	}
	set value(value) {
		this.fullValue = htmlPurifier.purify(value)
		setTimeout(() => {
			this.ref.innerHTML = this.fullValue;
			this.checkMaxLen()
		}, DOM_RENDER_TIMEOUT)
	}

	get color() {
		return getComputedStyle(this.ref).color;
	}
	set color(v) {
		this.ref.style.color = v;
	}

	get font() {
		return getComputedStyle(this.ref).fontFamily;
	}
	set font(value) {
		this.ref.style.fontFamily = value
	}

	// underline ?
	get textDecorationLine() {
		return getComputedStyle(this.ref).textDecorationLine;
	}
	set textDecorationLine(value) {
		this.ref.style.textDecorationLine = value
	}

	// italic ?
	get fontStyle() {
		return getComputedStyle(this.ref).fontStyle;
	}
	set fontStyle(value) {
		this.ref.style.fontStyle = value
	}

	onDestroy() {
		if(this.moveable) {
			this.moveable.destroy();
		}
	}

}
