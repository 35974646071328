<template>
    <section>
        <h4 v-if="mirrorFields.hasCssVarsMirrors" style="text-align: center">
            -- Global variables --
        </h4>
        <mirror-css-variable-fields
            :mirror-fields="mirrorFields"
            @change="changeField($event)"
            @reset="resetField($event)"
        ></mirror-css-variable-fields>
        <h4 v-if="mirrorFields.hasTextMirrors" style="text-align: center">
            -- texts --
        </h4>
        <div
            v-for="{ fieldName, templateIds } in mirrorFields.getTextMirrors()"
            :key="fieldName"
        >
            Text: <em>{{ fieldName }}</em> <sup>({{ templateIds.length }})</sup>
            <div class="text_field">
                <renewable-input
                    :placeholder="mirrorFields.getCombinedValue(fieldName)"
                    @change="
                        changeField({
                            value: $event.target.value,
                            fieldName: fieldName,
                            templateIds,
                            type: 'text',
                            key: 'value',
                        })
                    "
                    @reset="resetField({ fieldName: fieldName, templateIds })"
                    v-model="modelValues[fieldName]"
                ></renewable-input>
            </div>
        </div>
        <hr
            v-if="mirrorFields.hasImageMirrors && mirrorFields.hasTextMirrors"
            style="background-color: #663c07; height: 1px; margin: 1em 20px"
        />
        <h4 v-if="mirrorFields.hasImageMirrors" style="text-align: center">
            &#45;&#45; Images / icons &#45;&#45;
        </h4>
        <div
            v-for="{ fieldName, templateIds } in mirrorFields.getImageMirrors()"
            :key="'IMG_' + fieldName"
        >
            Image: <em>{{ fieldName }}</em>
            <sup>({{ templateIds.length }})</sup>
            <form-photos
                template-url=""
                :loading="mirrorImagesLoading"
                :file-list="imagesByField[fieldName] || []"
                :can-reset="true"
                :can-h-flip="true"
                :can-add-form-gallery="false"
                :photo-settings="
                    getGraphicSettings({
                        fieldName,
                        templateIds,
                        type: 'image',
                    })
                "
                loading-text="Image mirrors loading..."
                @uploadPhoto="
                    graphicUpload($event, {
                        fieldName,
                        templateIds,
                        mirrorType: 'imageMirror',
                    })
                "
                @removePhoto="
                    graphicDelete($event, {
                        fieldName,
                        templateIds,
                        mirrorType: 'imageMirror',
                    })
                "
                @switchPhoto="
                    graphicChange($event, {
                        fieldName,
                        templateIds,
                        type: 'image',
                    })
                "
                @horizontalFlipPhoto="
                    horizontalFlip($event, {
                        fieldName,
                        templateIds,
                        type: 'image',
                    })
                "
                @resetPhoto="
                    setGraphicField({
                        fieldName,
                        templateIds,
                        type: 'image',
                        key: 'url',
                        value: null,
                    })
                "
                @emptyPhoto="
                    setGraphicField({
                        fieldName,
                        templateIds,
                        type: 'image',
                        key: 'url',
                        value: '',
                    })
                "
            ></form-photos>
        </div>
        <hr
            v-if="
                mirrorFields.hasPhotoMirrors &&
                    (mirrorFields.hasImageMirrors ||
                        mirrorFields.hasTextMirrors)
            "
            style="background-color: #663c07; height: 1px; margin: 1em 20px"
        />
        <h4 v-if="mirrorFields.hasPhotoMirrors" style="text-align: center">
            &#45;&#45; Background images &#45;&#45;
        </h4>
        <div
            v-for="{ fieldName, templateIds } in mirrorFields.getPhotoMirrors()"
            :key="'PHOTO_' + fieldName"
        >
            Photo: <em>{{ fieldName }}</em>
            <sup>({{ templateIds.length }})</sup>
            <form-photos
                template-url=""
                :loading="mirrorPhotoLoading"
                :file-list="photosByField[fieldName] || []"
                :can-reset="true"
                :can-h-flip="true"
                :can-add-form-gallery="false"
                :photo-settings="
                    getGraphicSettings({
                        fieldName,
                        templateIds,
                        type: 'photo',
                    })
                "
                loading-text="Photo mirrors loading..."
                @uploadPhoto="
                    graphicUpload($event, {
                        fieldName,
                        templateIds,
                        mirrorType: 'photoMirror',
                    })
                "
                @removePhoto="
                    graphicDelete($event, {
                        fieldName,
                        templateIds,
                        mirrorType: 'photoMirror',
                    })
                "
                @switchPhoto="
                    graphicChange($event, {
                        fieldName,
                        templateIds,
                        type: 'photo',
                    })
                "
                @horizontalFlipPhoto="
                    horizontalFlip($event, {
                        fieldName,
                        templateIds,
                        type: 'photo',
                    })
                "
                @resetPhoto="
                    setGraphicField({
                        fieldName,
                        templateIds,
                        type: 'photo',
                        key: 'url',
                        value: null,
                    })
                "
                @emptyPhoto="
                    setGraphicField({
                        fieldName,
                        templateIds,
                        type: 'photo',
                        key: 'url',
                        value: '',
                    })
                "
            ></form-photos>
        </div>
    </section>
</template>

<script>
import FormPhotos from '@/components/project/components/form/photos/FormPhotos.vue'
import MirrorCssVariableFields from '@/components/project/components/mirror-fields/MirrorCssVariableFields.vue'
import { MirrorFields } from '@/components/project/model/MirrorFields'
import RenewableInput from '@/components/shared/form/RenewableInput.vue'
import { templateService } from '@/services/template.service'
import { normalizeUrl, sanitizeUrl } from '@/templating/templating-helpers'
import { VariableBinder } from '@/templating/variables/VariableBinder'
import Vue from 'vue'
import { mapActions } from 'vuex'

const MIRROR_TYPE = {
    imageMirror: 'imageMirror',
    photoMirror: 'photoMirror',
}

const clearRequests = () => ({
    [MIRROR_TYPE.imageMirror]: [],
    [MIRROR_TYPE.photoMirror]: [],
})

export default {
    name: 'MirrorTemplateFieldsUpdateForm',
    components: {
        MirrorCssVariableFields,
        // eslint-disable-next-line vue/no-unused-components
        FormPhotos,
        RenewableInput,
    },
    props: {
        mirrorFields: {
            type: MirrorFields,
        },
    },
    data() {
        return {
            req: {
                loadGraphics: clearRequests(),
            },
            updateTimers: {},
            resetTimers: {},
            modelValues: {},
            mirrorImagesLoading: true,
            mirrorPhotoLoading: true,
            imagesByField: {},
            photosByField: {},
        }
    },
    methods: {
        ...mapActions('connectionTemplate', ['updateHolder']),
        changeField({
            value,
            fieldName,
            templateIds,
            variableActionTriggers,
            type,
            key,
        }) {
            templateIds.forEach((templateId, idx) => {
                const templateModel = this.mirrorFields.getTemplateModelById(
                    templateId
                )
                clearTimeout(this.updateTimers[templateId])
                this.updateTimers[templateId] = setTimeout(() => {
                    this.emitUpdate(
                        { type, id: fieldName, key, value },
                        templateModel,
                        templateId
                    )
                    // Only with CSS Variables:
                    if (variableActionTriggers) {
                        this.changeByCssActionTrigger(
                            value,
                            variableActionTriggers,
                            templateModel,
                            templateId
                        )
                    }
                    this.updateHolder({
                        type,
                        id: fieldName,
                        key,
                        value,
                        templateId,
                    })
                }, 200 * idx++)
            })
        },
        emitUpdate({ type, id, key, value }, templateModel, templateId) {
            this.$emit('mirrorUpdated', {
                action: { type, id, key, value },
                templateModel,
                templateId,
            })
        },
        changeByCssActionTrigger(
            value,
            variableActionTriggers,
            templateModel,
            templateId
        ) {
            const allTriggers = variableActionTriggers[templateId] || []
            for (const trigger of allTriggers) {
                const [type, id, key] = trigger.split(
                    VariableBinder.triggerSeparator
                )
                this.emitUpdate(
                    { type, id, key, value },
                    templateModel,
                    templateId
                )
                this.updateHolder({ type, id, key, value, templateId })
            }
        },
        resetField({
            fieldName,
            templateIds,
            type = 'text',
            variableActionTriggers,
        }) {
            this.changeField({
                value: null,
                fieldName,
                templateIds,
                type,
                key: 'value',
                variableActionTriggers,
            })
            this.modelValues[fieldName] = ''
        },
        setGraphicField({ fieldName, templateIds, type, key, value }) {
            this.changeField({ value, fieldName, templateIds, type, key })
        },
        getGraphicSource(mirrorType = MIRROR_TYPE.imageMirror) {
            return mirrorType === MIRROR_TYPE.imageMirror
                ? this.imagesByField
                : this.photosByField
        },
        async loadMirroredGraphics() {
            this.cancelAllGraphicsRequests()
            this.imagesByField = {}
            this.photosByField = {}
            this.mirrorImagesLoading = true
            this.mirrorPhotoLoading = true
            await Promise.all([
                this.loadGraphicMirrorsOfType(
                    this.mirrorFields.getImageMirrors(),
                    MIRROR_TYPE.imageMirror
                ).finally(() => {
                    this.mirrorImagesLoading = false
                }),
                this.loadGraphicMirrorsOfType(
                    this.mirrorFields.getPhotoMirrors(),
                    MIRROR_TYPE.photoMirror
                ).finally(() => {
                    this.mirrorPhotoLoading = false
                }),
            ])
            this.req.loadGraphics = clearRequests()
        },
        async loadGraphicMirrorsOfType(
            graphicMirrors,
            mirrorType = MIRROR_TYPE.imageMirror
        ) {
            for (const { fieldName, templateIds } of graphicMirrors) {
                Vue.set(this.getGraphicSource(mirrorType), fieldName, [])
                for (const templateId of templateIds) {
                    const templateModel = this.mirrorFields.getTemplateModelById(
                        templateId
                    )
                    this.req.loadGraphics[mirrorType].push({
                        fieldName,
                        mirrorType,
                        request: templateService.graphics(
                            templateId,
                            templateModel.connectionId,
                            mirrorType
                        ),
                    })
                }
            }
            try {
                await Promise.all(
                    this.req.loadGraphics[mirrorType].map(
                        ({ fieldName, mirrorType, request }) =>
                            request.call().then(data => {
                                this.getGraphicSource(mirrorType)[
                                    fieldName
                                ].push(...data.map(this.mapToFilePhoto))
                            })
                    )
                )
            } catch (e) {
                console.error(e)
            }
        },
        graphicChange(file, { fieldName, templateIds, type }) {
            this.changeField({
                value: normalizeUrl(file.url),
                fieldName,
                templateIds,
                type,
                key: 'url',
            })
        },
        horizontalFlip(value, { fieldName, templateIds, type }) {
            this.changeField({
                value,
                fieldName,
                templateIds,
                type,
                key: 'hFlip',
            })
        },
        async graphicDelete(
            { file, fileList },
            { fieldName, templateIds, mirrorType }
        ) {
            const {
                firstTemplateId,
                templateModel: { connectionId },
            } = this.extractModelAndFirstTemplateId(templateIds)
            const graphicId = file.uid
            try {
                const removed = await templateService.removeGraphic(
                    firstTemplateId,
                    connectionId,
                    graphicId
                )
                if (removed) {
                    this.getGraphicSource(mirrorType)[fieldName] = fileList
                }
            } catch (e) {
                console.error('Cannot remove', mirrorType, e)
            }
        },
        async graphicUpload(file, { fieldName, templateIds, mirrorType }) {
            const {
                firstTemplateId,
                templateModel: { connectionId },
            } = this.extractModelAndFirstTemplateId(templateIds)
            try {
                const photo = await templateService.addGraphic(
                    firstTemplateId,
                    { file, type: mirrorType, id: fieldName, connectionId }
                )
                this.getGraphicSource(mirrorType)[fieldName].push(
                    this.mapToFilePhoto(photo)
                )
            } catch (e) {
                console.error('Cannot graphicUpload', mirrorType, e)
            }
        },
        extractModelAndFirstTemplateId(templateIds) {
            const [firstTemplateId] = templateIds
            const templateModel = this.mirrorFields.getTemplateModelById(
                firstTemplateId
            )
            return { templateModel, firstTemplateId }
        },
        getGraphicSettings({ fieldName, templateIds, type }) {
            const {
                templateModel: { settings },
            } = this.extractModelAndFirstTemplateId(templateIds)
            return settings[type][fieldName]
        },
        mapToFilePhoto({ id, url, name, template_id }) {
            return {
                uid: id,
                status: 'done',
                name: name,
                url: sanitizeUrl(
                    url,
                    templateService.getTemplateGraphicUrl(template_id)
                ),
            }
        },
        cancelAllGraphicsRequests() {
            ;[
                ...this.req.loadGraphics[MIRROR_TYPE.imageMirror],
                ...this.req.loadGraphics[MIRROR_TYPE.photoMirror],
            ].forEach(({ request }) => {
                request.cancel()
            })
            this.req.loadGraphics = clearRequests()
        },
    },
    beforeDestroy() {
        this.cancelAllGraphicsRequests()
    },
    watch: {
        mirrorFields: {
            handler(value) {
                if (value && value instanceof MirrorFields) {
                    this.loadMirroredGraphics()
                }
            },
            immediate: true,
        },
    },
}
</script>

<style scoped lang="less">
.text_field {
    padding: 0.2em 0 0.6em;
}
</style>
