<template>
	<div>

		<a-layout>
			<s-header
				@goToProjects="handleShowProjects()"
				@saveProject="saveProject($event)"
				@copyProject="copyProject($event)"
				@deleteProject="deleteProject($event)"
				@showTemplates="handleShowTemplates()"
				@showReports="handleShowReports()"
				@showUsers="handleShowUsers()"
				@showUserModal="userModal=true"
				@logout="handleLogOut()"
			/>

			<a-layout-content :style="containerStyle">
				<router-view></router-view>
				<s-info-modal v-if="isProjectInfoModalShown"/>
				<s-user-modal
					v-if="userModal"
					@closeUserModal = "userModal = false"
				/>
			</a-layout-content>
		</a-layout>
<!--
  TODO: remove when stable + remove from .css !!!
<div class="alerts">
			<a-alert
				v-if="alertVisible"
				:message="alert.text"
				:type="alert.type ? alert.type : 'info'"
				show-icon
				banner
			/>
		</div>-->
		<s-mask v-if="waiting">
			<a-spin size="large"/>
		</s-mask>
  </div>
</template>

<script>
import { userService } from '@/services/user.service'
import { mapActions, mapGetters, mapState } from 'vuex'
import Header from "./components/Header";
import Mask from "../layout/Mask";
import UserModal from "./components/UserModal";
import InfoModal from '@/components/main/components/InfoModal'

export default {
	name: 'Main',
	components: {
		SHeader: Header,
		sMask: Mask,
		SInfoModal: InfoModal,
		SUserModal: UserModal
	},
	data(){
		return {
			view: "projects",
			sorters: {
				query: "",
				sort: "name" //created, updated
			},
			tempSorters: {
				query: "",
				sort: "name"
			},
			userModal: false
		}
	},
	computed:{
		...mapState({
			waiting: state=> state.waiting,
			alert: state => state.alert,
			alertVisible: state => state.alertVisible,
		}),
    ...mapGetters("uiState", ["isProjectInfoModalShown", "currentSelectedProject"]),
    containerStyle() {
      return this.$route.path.includes('/gallery') ?
          { padding: 0 }
          :
          {}
    }
	},
	methods: {
		...mapActions(["showAlertSuccess"]),
		...mapActions("uiState", ["hideProjectInfoModal", "showRemoveModalConfirm"]),
		...mapActions("user", ["logOut"]),
		...mapActions("projects", ["renameProject","addProject","removeProject","duplicateProject"]),
    handleShowTemplates() {
      this.$router.push("/templates");
    },
    handleShowReports() {
      this.$router.push("/reports");
    },
    handleShowUsers() {
      this.$router.push("/users");
    },
    handleShowProjects() {
      this.view="projects";
      this.$router.push("/projects");
    },
		copyProject(project){
			this.duplicateProject(project);
			this.view = "projects";
		},
		deleteProject(project){
      this.showRemoveModalConfirm({
        title: 'Delete project?',
        content: 'If you delete project you will lose all graphics and bindings',
        okCallback: () => {
          this.removeProject(project);
          this.view = "projects";
        }
      })
		},
		saveProject(project){
      if(project.id){
        this.renameProject(project).catch(() => {});
      } else {
        this.addProject(project).then(p => {
          this.$router.push("/projects/" + p.id);
        }).catch(() => {});
      }
		},
    async handleLogOut() {
      // @Security: logout in backend FIRST and here (not from Vuex - it will loop)
      await userService.logOut();
      this.logOut();
      this.$router.push('/login');
    }
	}
}
</script>

<style lang="less">


</style>
