<template>
  <div class="form__group">
    <p class="form__label"> Global variables </p>
    <a-row>
      <template v-for="{key, type} of controls">
        <a-col :span="colSpan" class="control" :key="key" v-if="notFrozenAndWithoutPossibleValues(key)">
          <div style="margin: 0 10px; padding: 5px">
            <code>{{key}}</code>:
          </div>
          <div style="flex-grow: 1">
            <component
                v-if="type === 'text'"
                :is="getPossibleValues(key).length === 0 ? 'renewable-input' : 'renewable-select'"
                v-model="values[key]"
                :can-edit="!checkIsFieldFrozen(key)"
                :options="getPossibleValues(key)"
                @input="handleChange(key, $event)"
                @reset="handleReset(key)"
            />
            <renewable-select v-if="type === 'font'"
                              v-model="values[key]"
                              @change="handleChange(key, $event)"
                              @reset="handleReset(key)"
            >
              <a-select-option
                  v-for="fontName of filterFonts(getPossibleValues(key))"
                  :key="fontName"
              >
                {{ fontName }}
              </a-select-option>
            </renewable-select>
            <color-picker v-if="type === 'color'"
                          v-model="values[key]"
                          :transparency="true"
                          :possibleValues="getPossibleValues(key)"
                          :allow-to-pick-any-color="!checkIsFieldFrozen(key)"
                          @change="handleChange(key, $event)"
                          @reset="handleReset(key)"
            />
          </div>
        </a-col>
    </template>
    </a-row>
  </div>
</template>

<script>
import { possibleValuesMixin } from '@/components/project/components/form/possible-values.mixin';
import ColorPicker from '@/components/shared/form/ColorPicker'
import { filterFonts } from '@/components/shared/form/form-fonts';
import RenewableInput from '@/components/shared/form/RenewableInput'
import RenewableSelect from '@/components/shared/form/RenewableSelect'
import { SHAPE } from '@/components/templates/possible-values/possible-values-shape';
import { TemplateCssVars } from '@/templating/TemplateCssVars';
import { VariableBinder } from '@/templating/variables/VariableBinder'
import { debounce } from '@/view-helpers/debouncer'

const KEY_VALUE = 'value';

export default {
  name: 'FormCssVariables',
  components: {
    RenewableInput,
    RenewableSelect,
    ColorPicker
  },
  props: {
    compactUi: {
      type: Boolean,
      default: false,
    },
    cssVariables: {
      type: Object,
      default: () => ({})
    },
    fontList: {
      type: Array,
      default: () => []
    },
    templateControl: {
      type: TemplateCssVars
    },
    serverDefinedFrozenFields: {
      default: () => ({})
    }
    // serverDefinedPossibleValues from Mixin !
  },
  mixins: [ possibleValuesMixin ],
  data() {
    return {
      controls: [],
      values: {}
    }
  },
  computed: {
    SHAPE() {
        return SHAPE;
    },
    colSpan() {
      return this.compactUi ? 24 : 12;
    }
  },
  methods: {
    defineType(name) {
      const [type] = name.split('-').reverse();
      const DEFAULT_TYPE = 'text';
      const definedTypes = [DEFAULT_TYPE, 'color', 'font']
      return definedTypes.includes(type) ? type : DEFAULT_TYPE;
    },
    makeDataFromVariables() {
      this.controls = [];
      this.values = {};
      Object
         .entries(this.cssVariables || {})
         .forEach( ([key, {value}]) => {
           this.controls.push({key, type: this.defineType(key)})
           this.$set(this.values, key, value)
         })
    } ,
    handleChange: debounce(function (timer, key, value) {
      this.timer = timer;
      this.updateOnChange(key, value);
    }, 100),
    getActionTriggers(key) {
      const {triggers} = this.cssVariables[key] || {};
      return (triggers||[]).map(trigger => {
        const [type, id, key] = trigger.split(VariableBinder.triggerSeparator);
        return {type, id, key};
      });
    },
    updateOnChange(key, value) {
      this.$set(this.values, key, value)
      const actionTriggers = this.getActionTriggers(key);
      this.$emit('change', {key, value, actionTriggers})
    },
    handleReset(key) {
      const actionTriggers = this.getActionTriggers(key);
      this.$emit('reset', {key, actionTriggers})
    },
    getPossibleValues(type) {
      return this.possibleValues?.[type]?.[KEY_VALUE] || [];
    },
    checkIsFieldFrozen(type) {
      return this.serverDefinedFrozenFields?.[type]?.variable || false;
    },
    // @Business: Control should NOT be displayed when it is FROZEN nad without possibleValues
    notFrozenAndWithoutPossibleValues(type) {
       const withoutPossibleValues = this.getPossibleValues(type).length === 0
       const isFrozen = this.checkIsFieldFrozen(type)
       return !(isFrozen && withoutPossibleValues)
    },
    filterFonts(possibleFontValues) {
      if(!possibleFontValues || !possibleFontValues.length) {
         return this.fontList;
      }
      return filterFonts(this.fontList, possibleFontValues);
    }
  },
  watch: {
    cssVariables: {
      immediate: true,
      deep: true,
      handler() {
        this.makeDataFromVariables()
      }
    }
  },
  beforeDestroy () {
    if(this.timer) {
      clearTimeout(this.timer);
    }
  }
}
</script>

<style scoped>
   .control {
     display: flex;
   }
</style>
