import { connectionService } from '@/services/project-connection.service';
import { projectService } from '@/services/project.service'
import { makeCallWithPreloader } from '@/store/action-helpers'
import { useSelectedCreations } from '@/store/selected-creations';
import Vue from 'vue'

export default {
	state: {
		projectId: 0,
		thumbnailCreationId: null,
		firstCreationInProjectId: null,
		templates: [],
		connections: [],
		products: [],
		badges: [],
		customSizes: [],
		// project default badges moved to load server-side from now with lang support:
		defaultBadges: {
			pl: [],
			en: []
		},
		wasOpened: false,
		filterSizes: [],
		selectedCreations: {}
	},
	namespaced: true,
	getters: {
		projectId: state => state.projectId,
		templates: state => state.templates,
		connections: state => state.connections,
		products: state => state.products,
		projectBadges: state => state.badges,
		projectSizes: state => state.customSizes || [],
		projectSizesFilter: state => state.filterSizes || [],
		// @Business: this getter is parametrized due to i18n support in badges
		allProjectBadges: state => (lang = 'pl') => [...(state.defaultBadges[lang] || []), ...state.badges],
		thumbnailCreationId: state => state.thumbnailCreationId,
		// hasAllToAll need to be inside this calculation !! (otherwise we have stale state from back-end)
		firstCreationInProjectId: (state, getters) => getters.hasAllToAll ? state.firstCreationInProjectId : null,
		// [WARN!] this getter is crucial for some of components watchers - check them
		// before refactor / edit
		hasAllToAll: state => {
			if(state.templates.length > 0) {
				const anyGeneric = state.templates.filter(t => t.type === 'generic').length > 0;
				if(anyGeneric) {
					return true;
				}
				return state.products.length > 0
			}
			return false;
		},
		numberOfSelectedCreations: state => (connectionId) => state.selectedCreations[connectionId]?.length || 0,
		allSelectedCreations: state => Object.values(state.selectedCreations).flat()
	},
	mutations: {
		setProject (state , project ) {
			const {
				id, was_opened,
				templates, connections,
				products, badges, customSizes,
				thumbnail_creation_id, firstCreationInProjectId
			} = project;
			state.projectId = id;
			state.thumbnailCreationId = thumbnail_creation_id;
			state.firstCreationInProjectId = firstCreationInProjectId;
			state.templates = templates;
			state.connections = connections;
			state.products = products;
			state.badges = badges;
			state.customSizes = customSizes;
			state.wasOpened = was_opened;
			state.filterSizes = project?.config?.filterSizes || [];
			state.selectedCreations = {};
		},
		setEmptyProject(state) {
			state.projectId = 0;
			state.thumbnailCreationId = null;
			state.firstCreationInProjectId = null;
			state.templates = [];
			state.connections = [];
			state.products = [];
			state.badges = [];
			state.customSizes = [];
		},
		setProducts(state, products) {
			state.products = products;
		},
		setTemplates(state, templates) {
			state.templates = templates;
		},
		renameTemplate(state, {id, name}) {
			const template = state.templates.find(t => t.id === id);
			if(template) {
				template.name = name;
			}
		},
		addProduct(state, product) {
			state.products = [...state.products, product];
		},
		renameProduct(state, {id, name}) {
			const product = state.products.find(p => p.id === id);
			if(product) {
				product.name = name;
			}
		},
		addBadge(state, badge) {
			state.badges.push(badge)
		},
		addConnection(state, connection) {
			state.connections.push(connection)
		},
		ADD_SIZE(state, size) {
			state.customSizes.push(size)
		},
		REMOVE_SIZE(state, sizeId) {
			state.customSizes = state.customSizes.filter(s => s.id !== sizeId)
		},
		SET_FIRST_CREATION_ID(state, id) {
			state.firstCreationInProjectId = id;
		},
		SET_THUMBNAIL_CREATION_ID(state, id) {
			state.thumbnailCreationId = id;
		},
		SET_DEFAULT_BADGES(state, {badges, lang}) {
			Vue.set(state.defaultBadges, lang, badges);
		},
		SET_FILTERED_SIZES(state, sizes) {
			state.filterSizes = sizes;
		},
		RENAME_CONNECTION(state, {connectionId, name}) {
			const connection = state.connections.find(c => c.id === connectionId);
			if(connection) {
				connection.name = name;
			}
		},
		SELECT_CREATIONS(state, {connectionId, creations}) {
			Vue.set(state.selectedCreations, connectionId, creations);
		}
	},
	actions: {
		async loadProject({commit, dispatch}, projectId) {
			// When loading project - deselect all creations...
			useSelectedCreations().deselectAll();
			if(projectId === 'new') {
				dispatch('uiState/setCurrentProject', {}, {root: true})
				return commit('setEmptyProject');
			}
			const project = await makeCallWithPreloader(dispatch, () => projectService.getOne(projectId));
			commit('setProject', project)
			dispatch('markOpenedIfNeeded')
			dispatch('uiState/setCurrentProject', project, {root: true})
			dispatch('showAlertSuccess', `Project ${project.name} loaded`, {root: true});
			return project;
		},
		async markOpenedIfNeeded({state}) {
			const {projectId, wasOpened} = state;
			if(!wasOpened) {
				try {
					await projectService.markOpened(projectId)
					// eslint-disable-next-line no-empty
				} catch {}
			}
		},
		async convertBadgesToDifferentLang({getters}, {badges, lang}) {
			const otherLangBadges = getters.allProjectBadges(lang);
			return [...badges].map((badge) => {
				const otherBadge = otherLangBadges.find(({id}) => id === badge.id)
				return otherBadge ? {...otherBadge} : badge;
			})
		},
		async loadDefaultBadges({state, commit, dispatch}) {
			// Load badges ONLY if list of defaults by lang is empty !!!
			if(state.defaultBadges['pl'] && state.defaultBadges['pl'].length !== 0) {
				return;
			}
			let allBadges = {};
			try {
				allBadges = await projectService.getDefaultBadges();
				if(allBadges && Object.keys(allBadges).length) {
					for(const [lang, badges] of Object.entries(allBadges)) {
						commit('SET_DEFAULT_BADGES', {badges, lang})
					}
				}
			} catch(e) {
				dispatch('showAlertError', `Unable to load default badges for projects...`, {root: true});
			}
			return allBadges;
		},
		async loadTemplates({commit, dispatch, state}) {
			try {
				const templates = await projectService.getTemplates(state.projectId);
				commit('setTemplates', templates)
			} catch ( error ) {
				dispatch('showAlertError',`Template list could not be loaded: ${error.message}`, {root: true});
			}
		},
		async addTemplates({commit, state, dispatch}, templatesIds = []){
			const selectedIds = state.templates.map(t => t.id);
			const idsToSend = templatesIds.filter(id => !selectedIds.includes(id));
			if(idsToSend.length) {
				try {
					await projectService.addNewTemplates( state.projectId, idsToSend);
					await dispatch('loadTemplates')
					markAllToAllConnectionRefresh(commit);
				} catch ( error ) {
					dispatch('showAlertError',`Templates could not be added, ${error.message}`, {root: true});
				}
			}
		},
		async renameTemplate({commit, state, dispatch}, {id, name}) {
			try {
				await projectService.renameTemplate(state.projectId, id, name)
				commit('renameTemplate', {id, name});
			} catch ( error ) {
				dispatch('showAlertError',`Template rename failed, ${error.message}`, {root: true});
			}
		},
		async deleteTemplate({commit, state, dispatch}, id) {
			try {
				await projectService.removeTemplate( state.projectId, id );
				await dispatch('loadTemplates');
				// TODO: reload whole project (@Jabi solution for now)
				await dispatch('loadProject', state.projectId);
				markAllToAllConnectionRefresh(commit);
			} catch ( error ) {
				dispatch('showAlertError',`Template could not be deleted: ${error.message}`, {root: true});
			}
		},
		async addEmptyProducts({ dispatch, state }, productCount) {
			await makeCallWithPreloader(dispatch, () => projectService.addEmptyProducts(state.projectId, productCount));
			dispatch('loadProducts')
		},
		async loadProducts({dispatch, commit, state}) {
			try {
				const products = await projectService.getProducts(state.projectId);
				commit('setProducts', products);
			} catch ( error ) {
				dispatch('showAlertError',`Product list could not be loaded: ${error.message}`, {root: true});
			}
		},
		async addProducts({dispatch, commit, state}, {productIds = [], currency, lang}){
			const chosenIds = state.products.map(p => p.id);
			const idsToSend = productIds.filter(id => !chosenIds.includes(id));
			if (idsToSend.length) {
				try {
					await projectService.addNewProducts( state.projectId, {productIds: idsToSend, currency, lang});
					markAllToAllConnectionRefresh(commit)
					await dispatch('loadProducts')
				} catch ( error ) {
					dispatch('showAlertError',`Products could not be added: ${error.message}`, {root: true});
				}
			}
		},
		async renameProduct({commit, state, dispatch}, {id, name}) {
			try {
				await projectService.renameProduct(state.projectId, id, name)
				commit('renameProduct', {id, name});
			} catch ( error ) {
				dispatch('showAlertError',`Product name could not be changed: ${error.message}`, {root: true});
			}
		},
		async updateProductForAllConnections({state, commit, dispatch}, id) {
			try {
				await makeCallWithPreloader(dispatch, () => projectService.updateProductForAllConnections( state.projectId, id ));
				await dispatch('showAlertSuccess', `Product updated for all related connections`, {root: true});
				markCurrentConnectionRefresh(commit);
			} catch ( error ) {
				dispatch('showAlertError',`Product could not be updated: ${error.message}`, {root: true});
			}
		},
		async deleteProduct({commit, state, dispatch}, id) {
			try {
				await projectService.removeProduct( state.projectId, id );
				await dispatch('loadProducts');
				await dispatch('loadProject', state.projectId);
				markAllToAllConnectionRefresh(commit)
			} catch ( error ) {
				dispatch('showAlertError',`Product could not be removed: ${error.message}`, {root: true});
			}
		},
		async makeConnection( {state, commit, dispatch}, {templateId, productId, type, metaData}){
			if(templateId && (productId || type === 'generic')) {
				try {
					const connection = await projectService.addNewConnection( state.projectId, {
						templateId,
						productId,
						metaData,
						type
					} )
					commit('addConnection', connection);
					await dispatch('loadProject', state.projectId);
				} catch ( error ) {
					dispatch('showAlertError',`Failed to bind, ${error.message}`, {root: true});
				}
			}
		},
		async renameConnection({commit, state, dispatch}, {connectionId, name}) {
			try {
				await connectionService.renameConnection({ projectId: state.projectId, connectionId, name });
				commit('RENAME_CONNECTION', {connectionId, name});
				return true;
			} catch ( error ) {
				dispatch('showAlertError',`Failed to rename: ${error.message}`, {root: true});
			}
		},
		async deleteConnection( {state, dispatch}, id){
			try {
				await projectService.removeConnection( state.projectId, id );
				await dispatch('loadProject', state.projectId);
			} catch ( error ) {
				dispatch('showAlertError',`Product could not be removed: ${error.message}`, {root: true});
			}
		},
		addProjectCustomBadge( {commit}, badge){
			commit('addBadge', badge)
		},
		addCustomSize( {commit}, size){
			commit('ADD_SIZE', size)
		},
		removeCustomSize( {commit}, sizeId){
			commit('REMOVE_SIZE', sizeId)
		},
		updateProjectFirstCreationId({commit, getters}, id) {
			if(getters.firstCreationInProjectId === null) {
				commit('SET_FIRST_CREATION_ID', id);
			}
		},
		updateThumbnailCreationId({commit}, creationId) {
			commit('SET_THUMBNAIL_CREATION_ID', creationId);
		},
		updateFilteredSizes({commit}, sizes) {
			commit('SET_FILTERED_SIZES', sizes || []);
		},

		selectCreations({commit}, {connectionId, creations = []}) {
			commit('SELECT_CREATIONS', {connectionId, creations});
		}
	}
};

function markAllToAllConnectionRefresh(commit) {
	commit('connectionTemplate/SET_REFRESH_ALL_TO_ALL', true, { root: true })
}

function markCurrentConnectionRefresh(commit) {
	commit('connectionTemplate/SET_REFRESH_CURRENT', true, { root: true })
}
