
<template>
    <div class="items">
        <div class="title">
            <h3>Bindings</h3>
          <!--
            <a-button type="default" v-show="false">
                <a-dropdown>
                    <a class="ant-dropdown-link" @click.prevent="">
                        Export <a-icon type="down" />
                    </a>
                    <a-menu slot="overlay">
                        <a-menu-item @click="$emit('exportLinks', selectedLinks)" :disabled="!selectedLinks.length">
                            <p>Selected</p>
                        </a-menu-item>
                        <a-menu-item @click="$emit('exportLinks')">
                            <p>All</p>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
            </a-button>
          -->
        </div>
        <div class="links__search">
            <a-input-search enter-button placeholder="Search" v-model="query"/>
        </div>
        <s-empty v-if="filteredConnections.length === 0 && !showAllToAll"/>
        <ul v-else class="links__items">
            <li
                v-if="showAllToAll"
                class="links__item"
            >
                    <div class="items__name">All templates x All products</div>
                <a-dropdown>
                    <a class="ant-dropdown-link" @click.prevent="">
                        <a-icon type="more" />
                    </a>
                    <a-menu slot="overlay">
                        <a-menu-item @click="$emit('editLink', CONNECTION_ALL_TO_ALL)">
                            <span><a-icon type="caret-right" /> Edit</span>
                        </a-menu-item>
                        <a-menu-item @click="$emit('resetSettings', {
                          connectionId: CONNECTION_ALL_TO_ALL,
                        })">
                          <span class="danger"><a-icon type="sync" /> Reset all</span>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
            </li>
            <s-connection-list-item
                v-for="(connection, idx) in filteredConnections"
                class="links__item"
                :key="connection.id"
                :connectionCount="idx + 1"
                :productId="connection.product_id"
                :name="connection.name"
                :currency="getProductMeta(connection).currency"
                :lang="getProductMeta(connection).lang"
                @editLink="$emit('editLink',connection.id)"
                @renameLink="$emit('renameLink', {
                  connectionId: connection.id,
                  ...$event
                })"
                @resetSettings="$emit('resetSettings', {
                  connectionId: connection.id,
                })"
                @deleteLink="$emit('deleteLink',connection.id)"
                @moveSettings="$emit('moveSettings',connection.id)"
                @toggleSelect="toggleSelectLink(connection.id)"
            />
        </ul>
    </div>
</template>

<script>
import { CONNECTION_ALL_TO_ALL } from '@/routes-specials'
import Empty from "../../layout/Empty";
import ConnectionListItem from "./ConnectionListItem";

export default {
    name: 'ConnectionList',
    props: {
      connections: Array,
      showAllToAll: {
        type: Boolean,
        default: false
      }
    },
    components: {
        SConnectionListItem: ConnectionListItem,
        SEmpty: Empty
    },
    data(){
        return {
            query:"",
            selectedLinks: []
        }
    },
    computed: {
        filteredConnections(){
            return this.query
                ?
                this.connections.filter(l=>l.name.toLowerCase().indexOf(this.query.toLowerCase()) !== -1 )
                :
                this.connections;
        },
        CONNECTION_ALL_TO_ALL() {
          return CONNECTION_ALL_TO_ALL;
        }
    },
    methods: {
        toggleSelectLink(id){
            const index = this.selectedLinks.findIndex(l => l === id);
            if (index !== -1) {
                this.selectedLinks.splice(index, 1);
            } else {
                this.selectedLinks.push(id);
            }
        },
        getProductMeta(connection) {
          return connection?.metadata?.product || {};
        }
    }


}
</script>

<style lang="less">
    .items {
        width: 50%;

        .links__items {
            .links__item {
                justify-content: flex-start;

                .items__badge {
                    margin-right: 15px;
                }
                .items__name {
                    flex: 1;
                }
            }
        }

        &__badge {
            margin-left: 4px;
            margin-right: 4px;
            margin-bottom: 2px;
        }
    }

    .ant-checkbox-wrapper {
        display: flex; height: 40px; width: 100%; align-items: center;
    }
</style>
