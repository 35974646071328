var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"maskClosable":false,"title":'Possible Values for: ' + _vm.template.name,"width":"80vw","size":"small"},on:{"cancel":function($event){return _vm.handleModalClose(true)}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.hasAnyChanges)?_c('a-button',{key:"back",attrs:{"type":"default"},on:{"click":function($event){return _vm.handleModalClose()}}},[_vm._v(" Discard changes ")]):_vm._e(),_c('a-button',{key:"submit",attrs:{"type":"primary","disabled":!_vm.hasAnyChanges},on:{"click":function($event){return _vm.handleSaveClick()}}},[_vm._v(" Save possible values ")])]},proxy:true}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-tabs',{on:{"change":_vm.handleTabChange},scopedSlots:_vm._u([{key:"tabBarExtraContent",fn:function(){return [_c('a-popconfirm',{attrs:{"title":"Are you sure, that will overwrite all of current defaults!?","ok-text":"Yes","cancel-text":"No"},on:{"confirm":function($event){return _vm.handleLoadOptionalValuesFromTemplate()}}},[(_vm.hasInTemplateStaticValues)?_c('a-button',[_vm._v(" Load default from Template ")]):_vm._e()],1)]},proxy:true}])},_vm._l((_vm.languageTabs),function(ref){
var title = ref.title;
var language = ref.language;
var value = ref.value;
return _c('a-tab-pane',{key:value,scopedSlots:_vm._u([{key:"tab",fn:function(){return [(language === _vm.LANG_FALLBACK)?_c('div',[_vm._v(_vm._s(title))]):_c('locale-picker',{attrs:{"read-only":true,"show-label":false,"value":language}})]},proxy:true}],null,true)})}),1),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.possibleValueRows,"expand-row-by-click":true},scopedSlots:_vm._u([{key:"nameColumn",fn:function(ref){
var no = ref.no;
var group = ref.group;
var name = ref.name;
var frozen = ref.frozen;
return [(no)?[_vm._v(" "+_vm._s(name)+" "),(_vm.hasAnyFrozen(frozen))?[_c('a-icon',{staticClass:"ant-btn-link",attrs:{"type":"lock","title":_vm.getTruthyFrozenKeys(frozen).join(', ')}})]:_vm._e()]:_vm._e()]}},{key:"possibleValues",fn:function(ref){
var no = ref.no;
var group = ref.group;
var name = ref.name;
var values = ref.values;
var dataShape = ref.dataShape;
return [_c('possible-values-preview-table-cell',{attrs:{"field-name":name,"template-id":_vm.template.id,"values":values,"data-shape":dataShape}})]}},{key:"expandedRowRender",fn:function(ref){
var no = ref.no;
var group = ref.group;
var name = ref.name;
var values = ref.values;
var frozen = ref.frozen;
var dataShape = ref.dataShape;
return [(no)?_c('div',{staticStyle:{"margin-left":"0","display":"flex"}},[_c('div',{staticStyle:{"margin":"0 2em 0 17%","padding-right":"2em","border-right":"1px solid #7a7a7a"}},[_c('frozen-fields-divisor',{attrs:{"frozen-shape":_vm.getFrozenShapeFor(group),"base-frozen-values":frozen},on:{"update":function($event){return _vm.handleFrozenUpdated({
                                group: group,
                                name: name,
                                values: $event,
                            })}}})],1),_c('possible-values-divisor',{attrs:{"data-shape":dataShape,"data-values":values,"field-name":name,"fontNames":_vm.templateFontNames,"template-id":_vm.template.id},on:{"update":function($event){return _vm.handleValueUpdated({ group: group, name: name, values: $event })}}})],1):_vm._e()]}}])}),_c('section',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('div',{ref:"mountPoint"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }