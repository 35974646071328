import { MOVABLE_CONTROL_BTN } from '@/templating/movables/control-types';
import { addButton, ownerWrapper } from '@/templating/movables/movable-gui-helpers'
import { isPercent } from '@/templating/templating-helpers'
import Moveable from 'moveable'

function moveTo(target, x, y) {
	target.style.left = `${x}px`;
	target.style.top = `${y}px`;
}

function resizeTo(target, width, height) {
	target.style.width = `${width}px`;
	target.style.height = `${height}px`;
}

export function photoBackgroundMover({targetName, baseRect, baseX, baseY, width, height, onMove = () => {}, onResize = () => {}, imageRatio = 1, onDragEnd = () => {}, onResizeEnd = () => {}}) {
	const parentWrapper = ownerWrapper();
	const wrapperRect = parentWrapper.getBoundingClientRect();
	const moveByX = baseRect.x - wrapperRect.x;
	const moveByY = baseRect.y - wrapperRect.y;
	const startX = percentToNumberAdapt(baseX, baseRect.width, width) + moveByX;
	const startY = percentToNumberAdapt(baseY, baseRect.height, height) + moveByY;
	const scalableDiv = document.createElement('div');
	const editBtn = addButton({startX, startY, targetName, typeId: MOVABLE_CONTROL_BTN.photoBackgroundFrameToggle });
	scalableDiv.style.position = 'absolute';
	scalableDiv.style.top = `${startY}px`;
	scalableDiv.style.left = `${startX}px`;
	scalableDiv.style.width = width + 'px';
	scalableDiv.style.height = height + 'px';
	scalableDiv.style.zIndex = '999';
	parentWrapper.appendChild(scalableDiv);
	parentWrapper.appendChild(editBtn);
	const movableElement =  new Moveable(parentWrapper, {
		target: scalableDiv,
		origin: false,
		draggable: true,
		resizable: true,
		keepRatio: true,
		className: 'movable-red'
	}).on('drag', ({ target, left, top }) => {
		moveTo(target, left, top)
		moveTo(editBtn, left - 50, top)
		onMove({
			left: left - moveByX,
			top: top - moveByY
		})
	}).on('resize', ({target, width, height, left, top}) => {
		resizeTo(target, width, height)
		onResize({
			left: left - moveByX,
			top: top - moveByY,
			width,
			height
		})
	})
		.on('resizeEnd', onResizeEnd)
		.on('dragEnd', onDragEnd);

	const btnIcon = editBtn.querySelector('svg')
	editBtn.addEventListener('click', () => {
		const {style} = scalableDiv;
		const isShown = style.display === 'block' || !style.display;
		style.display = isShown ? 'none' : 'block';
		btnIcon.setAttribute('fill', isShown ? 'currentColor' : 'red');
		movableElement.updateRect();
		editBtn.blur();
	})

	return {
		destroy() {
			scalableDiv.remove();
			editBtn.remove();
			movableElement.destroy();
		},
		update(x, y, size) {
			const	calculateX = percentToNumberAdapt(x, baseRect.width, scalableDiv.style.width);
			const calculateY = percentToNumberAdapt(y, baseRect.height, scalableDiv.style.height);
			const calculateWidth = coverOrContainToNumberAdapt(size, width, baseRect.width);
			moveTo(scalableDiv, calculateX + moveByX, calculateY + moveByY)
			moveTo(editBtn, calculateX + moveByX - 50, calculateY + moveByY)
			resizeTo(scalableDiv, calculateWidth, calculateWidth / imageRatio)
			movableElement.updateRect();
		}
	}
}

function percentToNumberAdapt(value, baseDimension, relativeDimension){
	if(!value) {
		// fallback to 0! cause posX or posY default value is undefined
		return 0;
	}
	return isPercent(value) ?
		percentOf(baseDimension, value) - percentOf(relativeDimension, value)
		:
		value;
}

function coverOrContainToNumberAdapt(value, imageWidth, containerWidth) {
	if(value === 'contain') {
		return containerWidth;
	}
	if(value === 'cover') {
		return imageWidth;
	}
	if(isPercent(value)) {
		return percentOf(containerWidth, value)
	}
	return value
}

function percentOf(value, percent) {
	return parseFloat(value) * (parseFloat(percent) / 100)
}
