var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar",class:{'is-collapsed': _vm.collapsed},on:{"click":_vm.clickOutside}},[_c('div',{staticClass:"sidebar__main"},[_c('div',{staticClass:"sidebar__header",on:{"click":_vm.toggleCollapsed}},[(!_vm.collapsed)?_c('p',[_vm._v("Bindings")]):_vm._e(),(!_vm.collapsed && _vm.allSelectedCreationsCount > 0)?_c('div',{staticStyle:{"margin-left":"auto","margin-right":"1em"}},[_c('a-badge',{attrs:{"count":_vm.allSelectedCreationsCount,"overflow-count":Infinity,"number-style":{
                              backgroundColor: '#e5580b',
                              padding: '3px 6px',
                              color: '#0a0a0a',
                              fontSize: '12px' }}})],1):_vm._e(),_c('a-icon',{staticStyle:{"font-size":"20px"},attrs:{"type":_vm.collapsed ? 'menu-unfold' : 'menu-fold'},on:{"click":function($event){$event.stopPropagation();return _vm.toggleCollapsed.apply(null, arguments)}}})],1),_c('div',{staticClass:"sidebar__content scroll"},[(_vm.connections.length || _vm.hasAllToAll)?_c('a-menu',{staticClass:"sidebar__menu",attrs:{"mode":"inline","theme":"dark","inline-collapsed":_vm.collapsed,"selectedKeys":[_vm.selectedConnectionId]}},[(_vm.hasAllToAll)?_c('a-menu-item',{key:_vm.CONNECTION_ALL_TO_ALL,staticClass:"sidebar__item",class:{ 'is-warning':_vm.getAllWarnings() },on:{"click":function($event){return _vm.handleSelectConnection(_vm.CONNECTION_ALL_TO_ALL)}}},[_c('a-badge',{style:(_vm.selectedCountBadgeStyle),attrs:{"count":_vm.countConnectionCreations(_vm.CONNECTION_ALL_TO_ALL),"overflow-count":Infinity,"number-style":{
                              backgroundColor: '#e5580b',
                              padding: '3px 6px',
                              color: '#0a0a0a',
                              fontSize: '12px' }}}),_c('a-badge',{staticClass:"sidebar__badge"},[_c('a-icon',{staticStyle:{"margin-top":"8px","margin-left":"8px"},attrs:{"type":"copy"},scopedSlots:_vm._u([{key:"count",fn:function(){return undefined},proxy:true}],null,false,2759615466)})],1),_c('span',{staticClass:"sidebar__name"},[_vm._v(" All templates x All products ")])],1):_vm._e(),_vm._l((_vm.connections),function(connection,index){return _c('a-menu-item',{key:connection.id,staticClass:"sidebar__item",class:{ 'is-warning': _vm.getWarning() },on:{"click":function($event){return _vm.handleSelectConnection(connection.id)}}},[_c('a-badge',{style:(_vm.selectedCountBadgeStyle),attrs:{"count":_vm.countConnectionCreations(connection.id),"overflow-count":Infinity,"number-style":{
                            backgroundColor: '#e5580b',
                            padding: '3px 6px',
                            color: '#0a0a0a',
                            fontSize: '12px' }}}),_c('div',{staticClass:"sidebar__badge"},[_c('a-badge',{attrs:{"count":index + 1,"overflow-count":Infinity}})],1),(!_vm.collapsed)?_c('div',{staticClass:"sidebar__name",attrs:{"title":connection.name}},[(Boolean(connection.product_id))?_c('div',{staticClass:"sidebar__name__infobox"},[(_vm.getProductMeta(connection).lang)?_c('locale-picker',{attrs:{"display-type":"flag-only","show-label":false,"read-only":true},model:{value:(_vm.getProductMeta(connection).lang),callback:function ($$v) {_vm.$set(_vm.getProductMeta(connection), "lang", $$v)},expression:"getProductMeta(connection).lang"}}):_vm._e(),_c('div',{domProps:{"textContent":_vm._s(_vm.getProductMeta(connection).currency)}})],1):_vm._e(),_vm._v(" "+_vm._s(_vm.trimNameOverflow(connection.name))+" ")]):_vm._e(),(_vm.collapsed)?_c('div',{staticClass:"sidebar__name"},[_c('strong',[_vm._v(_vm._s(connection.name))]),_c('div',[_vm._v("ID: "+_vm._s(connection.id))]),(_vm.getProductMeta(connection).lang)?_c('locale-picker',{attrs:{"read-only":true},model:{value:(_vm.getProductMeta(connection).lang),callback:function ($$v) {_vm.$set(_vm.getProductMeta(connection), "lang", $$v)},expression:"getProductMeta(connection).lang"}}):_vm._e(),(_vm.getProductMeta(connection).currency)?_c('div',[_vm._v(" Currency: "+_vm._s(_vm.getProductMeta(connection).currency)+" ")]):_vm._e()],1):_vm._e()],1)})],2):_c('div',{staticClass:"sidebar__empty",on:{"click":function($event){return _vm.openLinks()}}},[(!_vm.collapsed)?_c('p',[_vm._v("Add bindings to start")]):_vm._e()]),_c('div',{staticClass:"sidebar__bottom"},[_c('a-button',{staticClass:"sidebar__bottom--btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.openLinks()}}},[_c('a-icon',{attrs:{"type":"plus"}}),(!_vm.collapsed)?_c('span',[_vm._v("Add / Edit bindings")]):_vm._e()],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }