<template>
  <a-input
      class="renewable-input"
      @change="$emit('change', $event)"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur', $event)"
      :placeholder="placeholder"
      v-model="controlledValue"
  >
    <template slot="suffix" v-if="!hideResetBtn">
      <a-button class="renewable-input__reset"
                title="Restore default value"
                @click="$emit('reset')"
      >
        <a-icon type="undo" />
      </a-button>
    </template>
  </a-input>
</template>

<script>
export default {
  name: 'RenewableInput',
  props: {
    value: {
      type: [String, Number],
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    hideResetBtn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      controlledValue: this.value
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.controlledValue = newValue
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="less">
  .renewable-input {
    &__reset {
      height: 25px;
      padding: 2px;
      border: 0
    }
  }
</style>
